import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { toast } from "react-toastify";
import PaymentInfor from "./PaymentInfor";
import TextField from "../../component/TextField";
import { OctTable } from "@oceantech/oceantech-ui";
import NumberField from "../../component/number-field";
import { useContext, useEffect, useRef, useState } from "react";
import { CODE, TYPE_WH, WF_TRACKER } from "../../utils/Constant";
import { Col, Form, Row } from "react-bootstrap";
import { AppContext } from "../../appContext/AppContext";
import LabelRequired from "../../component/LabelRequired";
import WfButton from "../../workflows/components/WfButton";
import { localStorageItem } from "../../utils/LocalStorage";
import FormUpdateVatTuPhieuThu from "./FormUpdateVatTuPhieuThu";
import { ColumnThongTinThuNgan } from "../const/ThuNganColumns";
import AutocompleteV2 from "../../component/AutocompleteObjectV2";
import { LIST_HINH_THUC, PATIENT_TYPE, TYPE_VT, WF_STATUS_ID } from "../const/ThuNganConstant";
import { cancelApproval, inPhieuXuatLe, inPhieuThuNgan, updateThongTinThanhToan, searchVatTuThanhToan } from "../services/PhanHeThuNganServices";
import "../PhanHeThuNgan.scss";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import ButtonIn from "../../component/button-in-phieu";
import { EnterReasonDialog } from "../../component/EnterReasonDialog";
import DanhSachPhieu from "../../component/DanhSachPhieu";
import { formatDateToDDMMYYYY } from "../../utils/FormatUtils";

type Props = {
  warehouseId?: any;
  selectedRow: any;
  setSelectedRow: React.Dispatch<any>;
  dsVatTu: any;
  dsPhieu: any[];
  handleRefreshData: (row: any, isUpdateSummaryStatus?: boolean, isUpdateDsBenhNhan?: boolean) => void;
  updateSummaryStatus: () => void;
  setDsVatTu: React.Dispatch<any>;
};
const validationSchema = Yup.object({
  discount: Yup.number().min(0, "Miễn giảm trong khoảng 0-100%").max(100, "Miễn giảm trong khoảng 0-100%"),
});

const ThongTinVienPhi = (props: Props) => {
  const { selectedRow, setSelectedRow, dsVatTu, dsPhieu, setDsVatTu, warehouseId, handleRefreshData } = props;
  const { setIsLoading } = useContext(AppContext);
  const dataUser = localStorageItem.get("current-user");
  const [invoicesInfor, setInvoicesInfor] = useState<any>({
    discount: '',
  });
  const [cancelInfor, setCancelInfor] = useState<any>();
  const [paymentItem, setPaymentItem] = useState<any>([]);
  const [dataReqWorkflow, setDataReqWorkflow] = useState<any>({});
  const [workflow, setWorkflow] = useState<any>();
  const submitFormRef = useRef<() => void>(() => { });

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const dataSubmit = {
        slipDto: {
          ...values,
          type: TYPE_WH.THU_NGAN,
          collected: values.amount * (1 - values.discount / 100) - values.collected,
          notCollected: 0,
        },
        lstItem: [],
        notUpdateLstItem: true,
      };
      const { data } = await updateThongTinThanhToan(selectedRow?.id, dataSubmit);
      if (CODE.SUCCESS === data.code) {
        toast.success('Cập nhật thông tin phiếu thu thành công')
      } else {
        setIsLoading(false);
        toast.error('Có lỗi xảy ra, vui lòng thử lại')
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSetPaymentInfor = (data: any) => {
    setPaymentItem([
      {
        name: "Tổng chi phí:",
        value: data?.amount,
        type: "price",
      },
      {
        name: "Đã thu:",
        value: data?.collected,
        type: "price",
      },
      {
        name: "Miễn giảm:",
        value: data?.discount,
        type: "percent",
      },
      {
        name: "Còn nợ:",
        value: data?.notCollected,
        type: "price",
        color: "text-danger",
      },
    ]);
  };

  useEffect(() => {
    if (cancelInfor) handleSetPaymentInfor(cancelInfor);
    else handleSetPaymentInfor(selectedRow);
  }, [selectedRow, cancelInfor]);

  useEffect(() => {
    if (selectedRow?.id) {
      const rowData = selectedRow || {};
      setInvoicesInfor({
        invDate: rowData.code ? new Date() : '',
        loaiPhieu: rowData.code ? 'Thu tiền' : '',
        cashierName: rowData.cashierName || '',
        id: rowData.id,
        code: rowData.code,
        amount: rowData.amount,
        method: LIST_HINH_THUC.find(method => method.code === rowData.method)?.code || LIST_HINH_THUC[0].code,
        methodName: LIST_HINH_THUC.find(method => method.code === rowData.method)?.name || LIST_HINH_THUC[0].name,
        reasonDis: rowData.reasonDis,
        contents: rowData.contents,
        discount: rowData.discount,
        receiptNumber: rowData.receiptNumber,
        mateWhId: rowData.mateWhId,
        mateWhName: rowData.mateWhName,
        collected: rowData.collected,
      });
    } else {
      setInvoicesInfor({});
    };
    setCancelInfor(null);
  }, [selectedRow]);

  const handleRefreshStatusColor = () => {
    const rowElement = document.getElementById(`lz-row-table-${selectedRow.id}`);
    if (rowElement) {
      const iconElement = rowElement.querySelector('i.bi-circle-fill') as HTMLElement;
      if (iconElement) {
        iconElement.style.color = selectedRow.color;
      }
    }
  };

  useEffect(() => {
    workflow?.currId === WF_STATUS_ID.PAID && !workflow.firstTime && submitFormRef.current();
    setTimeout(() => {
      workflow?.currCode && handleRefreshData(selectedRow, true);
    }, 300);
  }, [workflow?.currCode, workflow?.currId]);

  useEffect(() => {
    handleRefreshStatusColor();
  }, [selectedRow.color]);

  const handleCloseDialog = () => {
    setDataReqWorkflow({});
  };

  const handleApproveReq = async (values?: any) => {
    try {
      setIsLoading(true);
      const { data } = await cancelApproval(values, selectedRow.id);
      if (CODE.SUCCESS === data.code) {
        dataReqWorkflow.submit && dataReqWorkflow.submit();
        handleCloseDialog();
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại')
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectPhieu = (data: any) => {
    setCancelInfor(data);
  };

  const getDsVatTu = async (slipId: string) => {
    setIsLoading(true);
    try {
      const { data } = await searchVatTuThanhToan({ slipId: slipId });
      if (CODE.SUCCESS === data.code) {
        setDsVatTu(data.data.content);
      } else {
        toast.error('Lỗi tải danh sách thuốc/VT, vui lòng thử lại')
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cancelInfor) {
      getDsVatTu(cancelInfor?.id);
    } else if (selectedRow.id) {
      getDsVatTu(selectedRow.id);
    }
  }, [cancelInfor]);
  return (
    <div className="TTVienPhi">
      <div className="bg-white spaces pt-6 pb-10 spaces fl-1">
        <OctTable
          className={`${(workflow?.currId === WF_STATUS_ID.CANCEL || workflow?.currId === WF_STATUS_ID.WAIT_APPROVE) && selectedRow.canceler && !dsPhieu.length
            ? 'table-view-vat-tu-cancel-not-dsphieu'
            : ((workflow?.currId === WF_STATUS_ID.CANCEL || workflow?.currId === WF_STATUS_ID.WAIT_APPROVE) && selectedRow.canceler) || cancelInfor
              ? "table-view-vat-tu-cancel"
              : !dsPhieu.length
                ? "table-view-vat-tu-not-dsphieu"
                : "table-view-vat-tu"
            } custom-header-table`}
          columns={ColumnThongTinThuNgan}
          data={dsVatTu || []}
          noToolbar
          clearToolbar
          noPagination
          fixedColumnsCount={-1}
        />
      </div>
      <DanhSachPhieu
        currentReceiptNumber={selectedRow?.receiptNumber}
        dsPhieu={dsPhieu}
        handleSelectRowData={handleSelectPhieu}
      />
      <div>
        <PaymentInfor
          className="price-info"
          paymentData={paymentItem}
        />
        <div className="spaces ml-10 border-top fl-4">
          <Formik
            enableReinitialize
            initialValues={cancelInfor ? cancelInfor : invoicesInfor}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              submitFormRef.current = handleSubmit;
              return (
                <>
                  <Form onSubmit={handleSubmit} id="form-vien-phi">
                    <div>
                      <div className="flex-grow-1 mb-2 bg-white pe-3">
                        <div>
                          <Row>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Số phiếu"
                                  labelClassName="min-w-105px"
                                  className="input flex"
                                  name="receiptNumber"
                                  type="text"
                                  value={values?.receiptNumber || ""}
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Người thu"
                                  labelClassName="min-w-100px"
                                  className="input flex"
                                  name="cashierName"
                                  type="text"
                                  value={values?.cashierName || ""}
                                  disabled={true}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Ngày thu"
                                  labelClassName="min-w-80px"
                                  className="input flex"
                                  name="invDate"
                                  type="text"
                                  value={values.invDate ? moment(values.invDate).format("DD/MM/YYYY") : cancelInfor ? formatDateToDDMMYYYY(cancelInfor.doCreation) : ''}
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Loại phiếu"
                                  labelClassName="min-w-90px"
                                  className="input flex"
                                  name="loaiPhieu"
                                  type="text"
                                  value={values.loaiPhieu}
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <NumberField
                                  label="Số tiền"
                                  labelClassName="min-w-105px"
                                  className="input no-spinners text-end invTotal"
                                  name="amount"
                                  value={values?.amount || 0}
                                  disabled
                                  onChange={(value: number) => setFieldValue("amount", value)}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                {/* <NumberField
                                  label="Miễn giảm"
                                  labelClassName="min-w-105px"
                                  className="input no-spinners text-end"
                                  name="discount"
                                  value={values.discount || ""}
                                  onChange={(value: number) => setFieldValue("discount", value)}
                                /> */}
                                <TextField
                                  label="Miễn giảm (%)"
                                  labelClassName="min-w-100px"
                                  className="input flex"
                                  name="discount"
                                  value={values.discount || ""}
                                  disabled={!!cancelInfor || !invoicesInfor.id || workflow?.currId !== WF_STATUS_ID.WAIT_CREAT}
                                  onChange={(e: any) => {
                                    let discountValue = parseFloat(e.target.value);
                                    if (discountValue < 0 || isNaN(discountValue)) discountValue = 0;
                                    else if (discountValue > 100) discountValue = 100;
                                    setFieldValue("discount", discountValue);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="flex label-input-group mt-2">
                                <LabelRequired className="min-w-80px" label="Hình thức" />
                                <AutocompleteV2
                                  className=""
                                  options={LIST_HINH_THUC}
                                  name="method"
                                  value={{ code: values?.method || "CASH", name: values?.methodName || "Tiền mặt" }}
                                  onChange={(selectOption) => {
                                    setFieldValue('method', selectOption.code);
                                    setFieldValue('methodName', selectOption.name);
                                  }}
                                  isDisabled={!!cancelInfor || !invoicesInfor.id || workflow?.currId !== WF_STATUS_ID.WAIT_CREAT}
                                />
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Nội dung thu"
                                  labelClassName="min-w-90px"
                                  className="input flex"
                                  name="contents"
                                  value={values?.contents || ""}
                                  disabled={!!cancelInfor || !invoicesInfor.id || workflow?.currId !== WF_STATUS_ID.WAIT_CREAT}
                                />
                              </div>
                            </Col>
                            <Col xs={invoicesInfor?.visitObjectTypeName === PATIENT_TYPE.BHYT.name ? 6 : 12}>
                              <div className="label-input-group mt-2">
                                <TextField
                                  label="Lý do miễn giảm"
                                  labelClassName="min-w-105px"
                                  className="input flex"
                                  name="reasonDis"
                                  value={values?.reasonDis || ""}
                                  disabled={!!cancelInfor || !invoicesInfor.id || workflow?.currId !== WF_STATUS_ID.WAIT_CREAT}
                                />
                              </div>
                            </Col>
                            {(
                              ((workflow?.currId === WF_STATUS_ID.CANCEL || workflow?.currId === WF_STATUS_ID.WAIT_APPROVE) && selectedRow.canceler)
                              || cancelInfor
                            ) && cancelInfor?.receiptNumber != selectedRow?.receiptNumber && <>
                                <Col xs={4} className="pt-2 d-flex">
                                  <span className="text-lable-input min-w-105px">
                                    Ngày hủy:
                                  </span>
                                  <span className="color-red text-ellipsis-content">
                                    {cancelInfor?.dateForCancel}
                                  </span>
                                </Col>
                                <Col xs={4} className="pt-2 d-flex">
                                  <span className="text-lable-input min-w-80px">
                                    Người hủy:
                                  </span>
                                  <span className="color-red text-ellipsis-content">
                                    {cancelInfor?.canceler}
                                  </span>
                                </Col>
                                <Col xs={4} className="pt-2 d-flex">
                                  <span className="text-lable-input min-w-70px">
                                    Lý do hủy:
                                  </span>
                                  <span className="color-red text-ellipsis-content">
                                    {cancelInfor?.reasonForCancel}
                                  </span>
                                </Col>
                              </>}
                          </Row>
                        </div>
                      </div>
                      <div className="flex justify-content-start pe-2 pb-1 mt-3 wf-tu-truc">
                        {(!cancelInfor || (cancelInfor?.receiptNumber == selectedRow?.receiptNumber)) &&
                          <WfButton
                            handleClick={(data) => setDataReqWorkflow(data)}
                            additionalFunc={(workflow) => {
                              setWorkflow(workflow);
                            }}
                            tracker={WF_TRACKER.THU_NGAN}
                            objectId={selectedRow?.id}
                          />
                        }
                        {(((invoicesInfor.id && (workflow?.currId === WF_STATUS_ID.APPROVED || workflow?.currId === WF_STATUS_ID.PAID))) || cancelInfor) &&
                          <>
                            <ButtonIn
                              label="In phiếu thu"
                              className="spaces min-w-120 mx-3"
                              fetchExport={inPhieuThuNgan}
                              params={{
                                slipId: cancelInfor ? cancelInfor.id : selectedRow?.id,
                              }}
                            />
                            {(!cancelInfor || (cancelInfor?.receiptNumber == selectedRow?.receiptNumber)) &&
                              <ButtonIn
                                label="In phiếu xuất lẻ"
                                className="spaces min-w-120 mx-3"
                                fetchExport={inPhieuXuatLe}
                                params={{
                                  slipId: selectedRow?.id,
                                }}
                              />
                            }
                          </>
                        }
                      </div>
                    </div>
                  </Form>

                  {dataReqWorkflow?.componentName === "FormUpdateVatTuPhieuThu" &&
                    <FormUpdateVatTuPhieuThu
                      warehouseId={warehouseId}
                      data={selectedRow || {}}
                      show={dataReqWorkflow?.componentName === "FormUpdateVatTuPhieuThu"}
                      onHide={() => {
                        setDataReqWorkflow({});
                        handleRefreshData(selectedRow);
                      }}
                      idPhieuThanhToan={selectedRow?.id}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                    />
                  }

                  {dataReqWorkflow?.componentName === "ConfirmDialog" &&
                    <ConfirmDialog
                      show={dataReqWorkflow?.componentName === "ConfirmDialog"}
                      title={`Xác nhận ${dataReqWorkflow.reqName}`}
                      message={`Bạn chắc chắn muốn ${dataReqWorkflow.reqName} ?`}
                      close="Đóng"
                      onCloseClick={handleCloseDialog}
                      yes="Xác nhận"
                      onYesClick={() => {
                        dataReqWorkflow.submit && dataReqWorkflow.submit();
                        handleCloseDialog();
                      }}
                    />
                  }

                  {dataReqWorkflow?.componentName === "DialogEnterReason" &&
                    <EnterReasonDialog
                      show={dataReqWorkflow?.componentName === "DialogEnterReason"}
                      title={`Xác nhận ${dataReqWorkflow.reqName}`}
                      onHide={handleCloseDialog}
                      onSubmit={handleApproveReq}
                    />
                  }
                </>
              );
            }}
          </Formik>

        </div>
      </div>
    </div>
  );
};
export default ThongTinVienPhi;
