import { Button, Modal } from "react-bootstrap";
import { generateForm, generateHrTag } from "../../utils/AppFunction";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../appContext/AppContext";
import { Formik, Form, useFormikContext, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { CODE, DEFAULT_PAGE_INDEX, KEY, MAX_PAGE_SIZE, NAME_BUTTON_FLOW, SELECTION_MODE, TYPE_TU_TRUC, TYPE_WH, WF_TRACKER } from "../../utils/Constant";
import { toast } from "react-toastify";
import { PhanHeTuTrucContext } from "../PhanHeTuTruc";
import { formatDateDTO } from "../../utils/FormatUtils";
import { caculatorTonKhoTuTruc, createPhieuBoSungCoSoTuTruc, createPhieuLinh, createPhieuXuatSD, getDsVatTuPhieu, printPhieu, updateDsVatTuTuTruc, updatePhieuBoSungCoSoTuTruc, updatePhieuLinh, updatePhieuXuatSD } from "../services/TuTrucServices";
import WfButton from "../../workflows/components/WfButton";
import '../PhanHeTuTruc.scss';
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TablePagination } from "../../component/table/components/TablePagination";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import WfStatusSummary from "../../workflows/components/WfStatusSummary";
import { summaryStatus, TU_TRUC } from "../constants/TuTrucConstans";
import { TMCoSoTuTrucColumns } from "../constants/TuTrucColumns";
import moment from "moment";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { RESPONSE_CODE_SUCCESS } from "../../category-personnel/const/PersonnelConst";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { WMS_STATUS } from "../../phan-he-nhap-kho/constants/constansNhapKho";
import InputSearch from "../../component/InputSearch";
import ButtonIn from "../../component/button-in-phieu";
import { localStorageItem } from "../../utils/LocalStorage";

interface Props {
  show: boolean;
  typeTuTruc?: any;
  onHide: () => void;
  initialValues: any;
  modelId: any;
}

interface InventoryItem {
  iventory: number;
  code: string;
  unitofMeasure: string;
  materialGroup: number;
  materialType: string;
  name: string;
  peShipment: number;
  id: number;
  unitofMeasureName: string;
}

const DialogThemPhieuTuTruc = (props: Props) => {
  const { show, onHide, typeTuTruc, initialValues, modelId } = props;
  const { setIsLoading } = useContext(AppContext);
  const { dsVatTu } = useContext(PhanHeTuTrucContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [customSearchObject, setCustomSearchObject] = useState<any>({});
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [lstItem, setLstItem] = useState<any>([])
  const [itemSelected, setItemSelected] = useState<any>({} as any)
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false)
  const [idItem, setIdItem] = useState('');
  const [statusPhieu, setStatusPhieu] = useState<any>({ status: WMS_STATUS.warehouse_new });
  const formRef = useRef<any>()
  const [searchData, setSearchData] = useState<any>();
  const whIssueRef = useRef('')
  const slipIdRef = useRef('')

  const mappingType = {
    [TYPE_WH.BO_SUNG_CO_SO_TU_TRUC]: WF_TRACKER.BO_SUNG_CO_SO_TU_TRUC,
    [TYPE_WH.PHIEU_LINH]: WF_TRACKER.XUAT_KHO
  }
  const caculatorTotalAmount = (values: any) => Number(values?.totalQuantity) * (Number(values?.sellingPrice) + ((Number(values?.vat || 0) / 100) * Number(values?.sellingPrice)))
  const autoGenerateTracker = (type: number) => mappingType[type]
  const clearValues = (setFieldValue: FormikHelpers<Record<string, any>>['setFieldValue']) => {
    ["name", "totalQuantity", "unitOfMeasureName", "code", "specification", "manufacturer", "countryOfOrigin", "consignment", "expiryDate", "iventory", "sellingPrice", "vat", "totalAmount"].forEach((key: string) => {
      setFieldValue(`${modelId.id}.${key}`, '')
    })
  }
  useEffect(() => {
    setCustomSearchObject({
      whIssue: {
        cabinetType: typeTuTruc,
      },
    });
  }, [typeTuTruc]);
  useEffect(() => {
    generateHrTag('#kt_app_body > div.fade.page-full.spaces.h-calc-vh-50.modal.show > div > div > form > div.spaces.p-0.bg-white.modal-body > div > form > div:nth-child(1)')
  }, [listJsonGenerate])

  const FormObserver = () => {
    const { values, setValues, setFieldValue } = useFormikContext();

    useEffect(() => {
      const totalAmount = caculatorTotalAmount((values as any)[modelId.id]);
      setValues((prevValues: any) => ({
        ...prevValues,
        [modelId.id]: {
          ...prevValues[modelId.id],
          totalAmount: totalAmount
        }
      }))
    }, [(values as any)[modelId.id]?.totalQuantity, (values as any)[modelId.id]?.sellingPrice, (values as any)[modelId.id]?.vat]);

    useEffect(() => {
      if (whIssueRef.current != (values as any)[modelId.id]?.whIssue) {
        setLstItem([])
        setItemSelected({})
        clearValues(setFieldValue)
      }
      whIssueRef.current = (values as any)[modelId.id]?.whIssue
    }, [(values as any)[modelId.id]?.whIssue]);

    useEffect(() => {
      if (!itemSelected.idFake && !itemSelected.id) return;
      setFieldValue(modelId.id,
        initialValues[modelId.id]?.id ? {
          ...itemSelected,
          createdBy: initialValues[modelId.id].createdBy,
          whReceive: initialValues[modelId.id].whReceive,
          whIssue: initialValues[modelId.id].whIssue,
          notes: initialValues[modelId.id].notes,
          iventory: itemSelected?.iventoryInit ? itemSelected?.iventoryInit : itemSelected.iventoryWhIssue,
        } : {
          ...itemSelected,
          iventory: itemSelected?.iventoryInit ? itemSelected?.iventoryInit : itemSelected.iventoryWhIssue,
        })
    }, [itemSelected])

    useEffect(() => {
      if (!values) return;
      // Số lượng validate
      let eleTotalQuantity = document.querySelector("input[name='totalQuantity']");
      const handlerValidateSl = function (this: HTMLInputElement) {
        // Loại bỏ mọi ký tự không phải số
        if (!Number(this.value)) { this.value = this.value.replace(/[^0-9]/g, '') }
        // this.value = this.value.replace(/[^0-9]/g, '');

        // Nếu giá trị là trống, không làm gì cả
        if (this.value === '') return;

        // Chuyển giá trị sang số nguyên để kiểm tra
        const valueAsNumber = parseInt(this.value, 10);

        // Kiểm tra giá trị nhỏ hơn 1 thì đặt lại thành 1
        if (valueAsNumber < 1) {
          this.value = '1';
        }
      }
      if (eleTotalQuantity) {
        // Đặt thuộc tính min là 1
        eleTotalQuantity.setAttribute('min', '1');

        // Đảm bảo chỉ cho phép nhập số nguyên dương
        eleTotalQuantity.addEventListener('input', handlerValidateSl);
      }
      return () => {
        eleTotalQuantity?.removeEventListener('input', handlerValidateSl)
      };
    });

    return null;
  }

  const validationSchema = Yup.object({
    [modelId.id]: Yup.object({
    })
  })

  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const updateForm = async () => {
    const data = await generateForm(modelId.id, setIsLoading);
    setListJsonGenerate(data || []);
  };

  const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (modelId.type === TYPE_WH.BO_SUNG_CO_SO_TU_TRUC) return handlerSubmitBSCoSoTuTruc(values, formikHelpers)
    setIsLoading(true);
    try {
      const id = values[modelId.id]?.id;
      const warehouseId = values[modelId.id]?.whIssueId;
      const listItems = [...dsVatTu].map(item => ({
        itemId: item?.item?.id,
        totalQuantity: parseFloat(item.totalQuantity),
        warehouseId: warehouseId,
        lstDetail: [
          {
            quantity: parseFloat(item.totalQuantity),
            conversionQuantity: parseFloat(item.totalQuantity),
            binId: warehouseId,
            inputDate: formatDateDTO(new Date()),
            itemId: item?.item?.id,
            itemPrice: item?.item?.purchasePrice,
          }
        ]
      }));

      const dataSubmit = {
        slipDto: {
          ...values[modelId.id],
          type: modelId.type,
        },
        lstItem: listItems,
      };

      const { data } = modelId.type === TYPE_WH.PHIEU_LINH
        ? (id ? await updatePhieuLinh(id, dataSubmit) : await createPhieuLinh(dataSubmit))
        : (id ? await updatePhieuXuatSD(id, dataSubmit) : await createPhieuXuatSD(dataSubmit))

      if (id) {
        const res = await updateDsVatTuTuTruc({
          slipId: id,
          type: modelId.type,
          lstItem: listItems,
        });
        if (CODE.SUCCESS !== res.data?.code) {
          toast.error('Lỗi cập nhật danh sách vật tư, vui lòng thử lại')
          return;
        }
      }

      if (CODE.SUCCESS === data.code) {
        toast.success(`${values[modelId.id].id ? 'Cập nhật' : 'Thêm mới'} ${modelId.title} thành công`)
        onHide();
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại');
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSelectedRowsData = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getDsVatTuPhieu({ pageSize: MAX_PAGE_SIZE, pageIndex: DEFAULT_PAGE_INDEX, whIssueId: modelId.title ? initialValues[modelId.id]?.whIssueId : initialValues[modelId.id]?.whReceiveId, slipId: id, type: modelId.title ? 1 : 2 }); // 1 tủ trực 2 kho
      if (CODE.SUCCESS === data.code) {
        setLstItem([...data.data.content].map(values => ({ ...values.item, ...values, id: values.id, unitOfMeasureName: values.item.unitofMeasureName, iventory: modelId.title ? values.item?.iventory : values.iventoryWhIssue })));
      } else {
        toast.error('Có lỗi xảy ra, vui lòng thử lại');
      };
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialValues[modelId.id]?.id && getSelectedRowsData(initialValues[modelId.id]?.id);
  }, [initialValues[modelId.id]?.id]);

  useEffect(() => {
    updateForm();
  }, []);

  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center gap-3">
        {modelId.title && statusPhieu.status === WMS_STATUS.warehouse_new &&
          <>
            <CustomIconButton
              variant="edit"
              onClick={() => {
                setItemSelected({ ...props.cell.row.original });
              }}
            >
              <i className={`spaces bi bi-pencil-square text-navy ${(modelId.title) ? 'mr-5' : 'mx-0'}`}></i>
            </CustomIconButton>

            <CustomIconButton
              variant="delete"
              onClick={() => {
                setIdItem(props.cell.row.original.id ? props.cell.row.original.id : props.cell.row.original.idFake);
                setOpenDialogConfirmDelete(true);
              }}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          </>
        }
      </div>
    )
  };

  const handlerMedicine = (value?: any, values?: any, setFieldValue?: any) => {
    let obj = {
      ...values[modelId.id],
      iventory: Number(value.iventory),
      unitOfMeasureName: value.unitofMeasureName,
      code: value.code,
      CountryOfOrigin: null, //Nước sản xuất
      manufacturer: null, //Hãng sản xuất
      consignment: value.consignment, // Số lô
      specification: null, //Quy cách
      name: value.name,
      itemId: value.id,
      sellingPrice: Number(value.sellingPrice),
      serialNumber: value.serialNumber,
      expiryDate: value?.expiryDate
    }
    delete obj?.[modelId.id];
    setFieldValue(modelId.id, obj)
  }

  const generateSlipDto = (values: any) =>
  ({
    ...values[modelId.id],
    code: values[modelId.id].code,
    idFake: new Date(),
    sellingPrice: Number(values[modelId.id].sellingPrice) || 0,
    totalQuantity: Number(values[modelId.id].totalQuantity) || 0,
    totalAmount: caculatorTotalAmount(values[modelId.id]),
    vat: Number(values[modelId.id].vat) || 0,
    whReceiveName: values[modelId.id].whReceive.whReceiveName || values[modelId.id].whReceiveName,
    whReceive: values[modelId.id].whReceive.whReceiveCode || values[modelId.id].whReceive,
    whReceiveId: values[modelId.id].whReceive.id || values[modelId.id].whReceiveId,
    type: TU_TRUC.BO_SUNG_CO_SO // bổ sung cơ số
  })

  const handlerSubmitBSCoSoTuTruc = async (values: any, formikHelpers: FormikHelpers<any>) => {
    let id = initialValues[modelId.id]?.id || slipIdRef.current
    if (!lstItem.length) return toast.warning("Thông tin phiếu không có!")
    try {
      setIsLoading(true)
      const data = {
        slipDto: generateSlipDto(values),
        lstItem: lstItem.map((item: any) => {
          delete item.id
          item.iventory = Number(item.iventory)
          item.totalAmount = caculatorTotalAmount(item)
          return item
        })
      };
      let res = id ?
        await updatePhieuBoSungCoSoTuTruc(id, data) :
        await createPhieuBoSungCoSoTuTruc(data);
      if (res?.data?.code === RESPONSE_CODE_SUCCESS) {
        toast.success("Thàng công");
        slipIdRef.current = res?.data?.data?.id
        formikHelpers.setFieldValue(`${modelId.id}.id`, res?.data?.data?.id)
      }
    } catch (error) {
      console.error("ERR", error)
    } finally {
      setIsLoading(false)
    }
  }

  const handlerAddWithJs = async (values: any, setFieldValue: any) => {
    values[modelId.id].iventoryInit = values[modelId.id].iventory;
    // if(!values[modelId.id].iventory && (itemSelected?.idFake || itemSelected?.id)) return toast.warning("Vui lòng chọn lại thuốc để cập nhật lại số lượng tồn kho!")
    if (!values[modelId.id].iventory) return toast.warning("Vui lòng chọn thuốc!")
    if (!values[modelId.id].totalQuantity) return toast.warning("Số lượng không được để trống!")
    if (values[modelId.id].totalQuantity <= 0 || isNaN(values[modelId.id].totalQuantity) || values[modelId.id].totalQuantity > values[modelId.id].iventory) return toast.warning("Số lượng nhập không được vượt số lượng tồn trong kho!")
    let res = await caculatorTonKhoTuTruc({
      id: values[modelId.id].itemId,
      warehouseId: values[modelId.id]?.warehouseId || values[modelId.id]?.whReceive?.id || values[modelId.id]?.whReceiveId,
      serialNumber: values[modelId.id].serialNumber,
      consignment: values[modelId.id].consignment
    })
    values[modelId.id].iventory = res.data.data;
    if (itemSelected.idFake || itemSelected.id) { // Cập nhật
      let arr = lstItem.map((item: any) => {
        if (item.idFake !== itemSelected.idFake || item.id !== itemSelected.id) return item;
        return { ...itemSelected, ...values[modelId.id], totalAmount: caculatorTotalAmount(values[modelId.id]) }
      })
      setItemSelected({})
      setLstItem(arr)
    }

    clearValues(setFieldValue)
    !itemSelected.idFake && !itemSelected.id && setLstItem([...lstItem, generateSlipDto(values)])
  }

  const handleDeletePhieu = () => {
    let arr = [...lstItem.filter((item: any) => ![item.idFake, item.id].includes(idItem))]
    setLstItem(arr)
    setOpenDialogConfirmDelete(false)
  }


  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => {
          return <Form ref={formRef} onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px">
              <div className="d-flex gap-6">
                <div
                  className="d-flex align-items-center title-back gap-3 cursor-pointer"
                  onClick={onHide}
                >
                  <i className="spaces bi bi-chevron-left ml-10"></i>
                  <div className="spaces mr-20 my-3">Quay lại</div>
                </div>
                {
                  modelId.title &&
                  <div className="spaces mt-4 title">
                    {`${values[modelId.id]?.id || slipIdRef.current ? 'Cập nhật' : 'Thêm mới'} ${modelId.title}`}
                  </div>
                }
              </div>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces px-10 mb-10">
                <h4 className="text-title fw-bold fs-5 spaces mb-5">Thông tin phiếu</h4>
                <GenerateFormComponent
                  customSearchObject={customSearchObject}
                  warehouseId={values[modelId.id]?.whIssueId || initialValues[modelId.id]?.whIssueId}
                  listFieldAuto={listJsonGenerate}
                  handleChange={handleChange}
                  isIventoryNoZero={true}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  errors={errors}
                  propsRenderButton={(e: any) => handlerMedicine(e, values, setFieldValue)}
                  modelID={modelId.id}
                  customComponent={DynamicComponent}
                  isViewSelectThuoc={!values[modelId.id]?.whIssue || !modelId.title || statusPhieu.status !== WMS_STATUS.warehouse_new}
                  isView={!modelId.title || statusPhieu.status !== WMS_STATUS.warehouse_new}
                />
                {
                  modelId.type === TYPE_WH.NHAP_BU_CO_SO_TU_TRUC && modelId.title &&
                  <div className="d-flex spaces pr-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                    <div className="flex-grow-1 ">
                      <InputSearch
                        name="keyword"
                        value={searchData?.keyword || ''}
                        placeholder="Tìm kiếm"
                        handleChange={handleChangeSearchData}
                        handleSearch={() => { }}
                        // handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && getSelectedRowsData()}
                        className="spaces h-31 pr-4  radius-3"
                      />
                    </div>
                  </div>
                }
                {
                  modelId.type === TYPE_WH.BO_SUNG_CO_SO_TU_TRUC && modelId.title &&
                  <Button disabled={!modelId?.title || WMS_STATUS.warehouse_new !== statusPhieu?.status} className="btn btn-fill custom-position-btn spaces min-w-90px mt-10" onClick={() => handlerAddWithJs(values, setFieldValue)}>
                    {(itemSelected.idFake || itemSelected.id) ? 'Cập nhật' : 'Thêm'}
                  </Button>
                }
              </div>
              <div className=" spaces bg-white mb-0">
                <TableCustom<any>
                  key={statusPhieu?.status}
                  className="spaces table-ds-thuoc"
                  data={lstItem}
                  columns={TMCoSoTuTrucColumns}
                  columnsActions={columnsActions}
                  verticalScroll={true}
                  selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                />
                {/* <TablePagination
                  handlePagesChange={handlePagesChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={rowsPerPage}
                  rowsForPage={rowsForPage}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  totalPages={configTable.totalPages}
                  totalElements={configTable.totalElements}
                  numberOfElements={configTable.numberOfElements}
                  hideTotalElements
                  // customComponent={<WfStatusSummary hiddenQuantity summaryStatus={summaryStatus} col={3} />}
                  customComponentClassName="min-w-600px"
                  className="border border-0"
                /> */}
              </div>
            </Modal.Body>
            <div className="bottom-button d-flex justify-content-start w-100 bg-white spaces py-6">
              <WfButton
                key={values[modelId.id]?.id}
                type={modelId.title ? 1 : 2} // 1 tủ trực 2 kho xuất
                tracker={autoGenerateTracker(modelId.type)}
                objectId={values[modelId.id]?.id}
                additionalFunc={(data) => {
                  setStatusPhieu({
                    status: data?.currCode
                  })
                  data?.currCode && setFieldValue(`${modelId.id}.statusCode`, data?.currCode)
                }}
              />
              {(WMS_STATUS.warehouse_new === statusPhieu?.status) && modelId.title &&
                <Button type="submit" className="btn btn-fill min-w-90px spaces ms-1">
                  Lưu
                </Button>
              }
              {(![WMS_STATUS.warehouse_new].includes(values[modelId.id]?.statusCode) && values[modelId.id]?.statusCode) &&
                <ButtonIn
                  className="spaces min-w-120 mx-3"
                  fetchExport={printPhieu}
                  params={{
                    slipId: values[modelId.id]?.id,
                    type: modelId.title ? 1 : 2 // 1 tủ trực 2 kho xuất
                  }}
                />
              }
            </div>
            <FormObserver />
          </Form>
        }}
      </Formik>
      {openDialogConfirmDelete &&
        <ConfirmDialog
          show={openDialogConfirmDelete}
          onCloseClick={() => setOpenDialogConfirmDelete(false)}
          onYesClick={handleDeletePhieu}
          title="Xóa phiếu"
          message={`Bạn chắc chắn muốn xóa ?`}
          yes="Xóa"
          close="Hủy"
        />
      }
    </Modal>
  );
};

export default DialogThemPhieuTuTruc;
