import { Formik } from "formik";
import { FC, SetStateAction, createContext, useEffect, useState } from "react";
import FormNhapKho from "./components/FormNhapKho";
import * as Yup from "yup";
import { getListWarehouse } from "../utils/ServicesUtils";

export interface PhanHeNhapKhoContextProps {
    dsNhapKho: any;
    setDsNhapKho: SetStateAction<any>;
    detailPhieuNhap: any;
    setDetailPhieuNhap: SetStateAction<any>;

}

const initialContext = {
    dsNhapKho: [],
    setDsNhapKho: () => { },
    detailPhieuNhap: [],
    setDetailPhieuNhap: () => { },
}

export const PhanHeNhapKhoContext = createContext<PhanHeNhapKhoContextProps>(initialContext)
interface Props {
    warehouseId: number;
}
export const PhanHeNhapKho = (props: Props) => {
    const { warehouseId } = props;
    const [dsNhapKho, setDsNhapKho] = useState([]);
    const [detailPhieuNhap, setDetailPhieuNhap] = useState({});
    const [lstWarehouse, setLstWarehouse] = useState<any>({});

    let validationSchema = Yup.object({});
    const handleFormSubmit = (values: any) => {}

    const updateData = async () => {
        try {
          const data = await getListWarehouse();
          let newLstData: any = {};
          data.forEach((item: any) => {
            newLstData[item.id] = {
              mateWhId: item.id,
              mateWhName: item.name,
              mateWh: item.code,
            };
          });
          setLstWarehouse(newLstData);
        } catch (error) {
          console.error(error);
        }
      };
  
      useEffect(() => {
        updateData();
      }, []);

    return (
        <Formik<any>
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            <PhanHeNhapKhoContext.Provider
                value={{
                    dsNhapKho: dsNhapKho,
                    setDsNhapKho: setDsNhapKho,
                    detailPhieuNhap,
                    setDetailPhieuNhap,
                }}
            >
                <div className="bg-white">

                    <div className="flex-1 flex flex-column">
                        <div className="py-1">
                            <FormNhapKho
                                LST_WAREHOUSE={lstWarehouse}
                                warehouseId={warehouseId}
                            />
                        </div>
                    </div>


                </div>

            </PhanHeNhapKhoContext.Provider>
        </Formik>

    )
}

export default PhanHeNhapKho;
