import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { IconButtonSave } from "../../component/IconSvg";
import { generateValidationSchema } from "../../utils/AppFunction";
import { CODE } from "../../utils/Constant";
import { addDanhMuc, updateDanhMuc } from "../DanhMucServices";
import FormVatTuDanhMuc from "./FormVatTuDanhMuc";
import { formatDateDTO } from "../../utils/FormatUtils";

interface Props {
  show?: boolean;
  listJsonGenerate?:any;
  onHide: () => void;
  initialValues?: any;
  dataEdit?: any;
  item?: any;
}

const DialogThemDanhMuc = (props: Props) => {
  const {
    show,
    onHide,
    item,
    dataEdit,
    listJsonGenerate,
    initialValues = {
      code: "",
      name: "",
      taxCode: "",
    },
  } = props;
  
  const { setIsLoading } = useContext(AppContext);
  const [isView, setIsView] = useState<boolean>();
  const [lstVatTu, setLstVatTu] = useState<any>([]);
  const [indexRowEdit, setIndexRowEdit] = useState<number | null>(null);
  const [customFieldReadOnly, setCustomFieldReadOnly] = useState<any>();
  
  const handleSaveDM = async (values: any) => {    
    const dataSubmit = {
      ...values[item?.code],
      doCreation:
        values[item?.code]?.termModelId || values[item?.code]?.id
          ? formatDateDTO(values[item?.code]?.doCreation)
          : formatDateDTO(new Date()),
    };
    try {
      setIsLoading(true);
      const id = values[item?.code]?.termModelId || values[item?.code]?.id;
      if (item.description?.formVatTu) {
        dataSubmit.lstItem = lstVatTu;
      };      
      const { data } = id
        ? await updateDanhMuc(listJsonGenerate?.path, dataSubmit)
        : await addDanhMuc(listJsonGenerate?.path, dataSubmit);
      if (CODE.SUCCESS === data?.code) {
        setIsView(true);
        setIsLoading(false);
        toast.success((id ? "Cập nhật" : "Thêm") + ` ${item?.name} thành công`);
        onHide();
      } else {
        let errorMesage =
          data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    }
  };

  useEffect(() => {
    setCustomFieldReadOnly({
      codeExport: isView,
      reason: isView,
      notes: isView,
    });
  }, []);

  return (
    <Modal centered show={show} onHide={onHide} size={item.description?.sizeForm || "lg"} className="spaces ">
      <Formik
        enableReinitialize
        initialValues={{ [item?.code]: initialValues }}
        validationSchema={generateValidationSchema(listJsonGenerate, item.code)}
        onSubmit={handleSaveDM}
      >
        {({
          values,
          touched,
          errors,
          setValues,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="">
              <Modal.Header className="header-modal min-h-40px">
                <div className="d-flex gap-6">
                  <div
                    className="d-flex align-items-center title-back gap-3 cursor-pointer"
                    onClick={onHide}
                  >
                    <i className="spaces bi bi-chevron-left ml-10"></i>
                    <div className="spaces mr-20 my-3">Quay lại</div>
                  </div>
                  <div className="spaces mt-4 title">{`${values[item?.code]?.id ? 'Cập nhật' : 'Thêm'} ${item?.name}`}</div>
                </div>
              </Modal.Header>
              <Modal.Body className="spaces p-0 bg-white">
                <div className="spaces px-10">
                  <GenerateFormComponent
                    isView={!!values[item?.code]?.isWarehouseReceipt}
                    customFieldReadOnly={customFieldReadOnly}
                    listFieldAuto={listJsonGenerate}
                    itemEdit={dataEdit}
                    handleChange={handleChange}
                    setValues={setValues}
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    modelID={item?.code}
                  />
                </div>

                {item?.description?.formVatTu &&
                  <div className="">
                    <FormVatTuDanhMuc
                      isView={!!values[item?.code]?.isWarehouseReceipt}
                      dataEdit={dataEdit}
                      type={item}
                      lstVatTu={lstVatTu}
                      setLstVatTu={setLstVatTu}
                      indexRowEdit={indexRowEdit}
                      setIndexRowEdit={setIndexRowEdit}
                    />
                  </div>
                }

                <div className=" d-flex w-100 spaces py-6 pl-5 mt-10">
                  {!values[item?.code]?.isWarehouseReceipt &&
                    <Button
                      type="submit"
                      className="btn btn-fill min-w-120px spaces ml-5"
                    >
                      <IconButtonSave /> Lưu
                    </Button>
                  }
                  {!!values[item?.code]?.isWarehouseReceipt &&
                    <Button
                      onClick={onHide}
                      className="btn btn-fill min-w-120px spaces ml-5"
                    >
                      Quay lại
                    </Button>
                  }
                </div>
              </Modal.Body>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DialogThemDanhMuc;
