import { Column } from "react-table";
import { TableCustomHeader } from "../components/TableCustomHeader";
import { TableCustomCell } from "../components/TableCustomCell";
import { ColumnConfig } from "./ColumnConfig";

export const generateColumns = (
  columnConfigs: ColumnConfig[]
): ReadonlyArray<Column<any>> => {
  return columnConfigs.map((config) => {
    return {
      Header: (props) => {

        let classConfig = config.classColumn ? config.classColumn : "";

        return (
          <TableCustomHeader<any>
            tableProps={props}
            title={config.title}
            className={`text-center text-light ${classConfig}`}
          />
        );
      },
      id: config.id,
      Cell: ({ ...props }) => {

        let currentValue = props.data[props.row.index]?.[props?.column?.id];
        let formattedValue = currentValue !== undefined && currentValue !== null
          ? config?.formatFunc
            ? config?.formatFunc(currentValue)
            : currentValue
          : "";
        let classConfig = config.classCell ? config.classCell : "";

        if (config?.id === "stt") {
          return (
            <TableCustomCell
              className={classConfig}
              data={props.row.index + 1}
            />
          );
        }
        return (
          <TableCustomCell
            className={classConfig}
            data={String(formattedValue)}
          />
        );
      },
    };
  });
};