import { Formik } from "formik";
import { FC, createContext } from "react";
import TableDSVatTu from "./component/TableDSVatTu";

export interface QuanLyKhoContextProps { };

interface TheKhoProps {}

const initialContext = {};

export const QuanLyKhoContext = createContext<QuanLyKhoContextProps>(initialContext)

export const QuanLyKho: FC<TheKhoProps> = (props) => {
    return (
        <Formik<any>
            initialValues={{}}
            onSubmit={() => { }}
        >
            <QuanLyKhoContext.Provider
                value={{}}
            >
                <div className="bg-white">
                    <div className="flex-1 flex flex-column">
                        <div className="py-1 ">
                            <TableDSVatTu />
                        </div>
                    </div>
                </div>
            </QuanLyKhoContext.Provider>
        </Formik>
    )
}

export default QuanLyKho;
