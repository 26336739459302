import { Dispatch, SetStateAction, useContext } from "react";
import { OctTable } from "@oceantech/oceantech-ui";
import { PhanHeDieuChuyenContext } from "../PhanHeDieuChuyen";
import { formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import TextField from "../../component/TextField";
import { STATUS_DIEU_CHUYEN } from "../constants/constansDieuChuyen";

interface Props {
  setInitialValuesVatTu: Dispatch<SetStateAction<any>>
  setIndexRowEdit: Dispatch<SetStateAction<any>>
  setIsChangeData: Dispatch<SetStateAction<any>>
  indexRowEdit: number | null
  workFlow: any
  isView: boolean
  isTransfer: boolean
};

const TableThongTinDieuChuyen = (props: Props) => {
  const { setInitialValuesVatTu, setIndexRowEdit, setIsChangeData, indexRowEdit, workFlow, isView, isTransfer } = props;
  const { setDsDieuChuyen, dsDieuChuyen } = useContext(PhanHeDieuChuyenContext);

  const hanldeDeleteRow = (index: number) => {
    const data = [...dsDieuChuyen].filter((_: any, i: number) => i !== index);
    setDsDieuChuyen(data);
  };

  const handleChangeAcceptQuantity = (e: React.ChangeEvent<HTMLInputElement>, rowData: any, index: number) => {
    setIsChangeData(true);
    let quantity = parseFloat(e.target.value);
    if (quantity < 0 || isNaN(quantity)) quantity = 0;
    else if (quantity > rowData.totalQuantity) quantity = rowData.totalQuantity || 0;

    const updatedDsDieuChuyen = [...dsDieuChuyen];
    updatedDsDieuChuyen[index] = {
      ...updatedDsDieuChuyen[index],
      accept: quantity,
    };

    setDsDieuChuyen(updatedDsDieuChuyen);
  };


  const columns = [
    {
      name: "STT",
      field: "",
      headerStyle: {
        minWidth: 50,
        maxWidth: 50,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>,
    },
    ...(isView ? [] : [
      {
        name: "Thao tác",
        field: "",
        headerStyle: {
          minWidth: 100,
          maxWidth: 100,
        },
        cellStyle: {
          textAlign: "left",
        },
        render: (row: any, index: number, stt: number) => (
          <div className="d-flex justify-content-center gap-3">
            {indexRowEdit !== index && <>
              <CustomIconButton
                variant="edit"
                onClick={() => {
                  setInitialValuesVatTu({ vatTuDieuChuyen: { ...row.item, ...row } });
                  setIndexRowEdit(index);
                }}
              >
                <i className="bi bi-pencil-square text-navy"></i>
              </CustomIconButton>

              <CustomIconButton variant="delete" onClick={() => hanldeDeleteRow(index)}>
                <i className="bi bi-trash3-fill text-danger"></i>
              </CustomIconButton>
            </>}
          </div>
        ),
      }]),
    {
      name: "Mã thuốc/VT",
      field: "",
      headerStyle: {
        minWidth: 110,
        maxWidth: 110,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.code || row.item?.code,
    },
    {
      name: "Tên thuốc",
      field: "",
      headerStyle: {
        minWidth: 220,
        maxWidth: 220,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.name || row.item?.name,
    },
    {
      name: "Số lượng",
      field: "",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.totalQuantity),
    },
    // ...(!isTransfer ? [] : [
    {
      name: "Số lượng duyệt",
      field: "",
      headerStyle: {
        minWidth: 120,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => workFlow?.currId !== STATUS_DIEU_CHUYEN.WAIT_APPROVE ? row.accept : (
        <TextField
          disabled={!isTransfer}
          name="accept"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeAcceptQuantity(e, row, index)}
          value={row.accept || ''}
        />
      ),
    },
    // ]),
    {
      name: "Đơn vị",
      field: "",
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.unitofMeasureName || row.item?.unitofMeasureName,
    },
    {
      name: "Đơn giá",
      field: "",
      headerStyle: {
        minWidth: 120,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.sellingPrice || row.item?.sellingPrice),
    },
    {
      name: "% VAT",
      field: "",
      headerStyle: {
        minWidth: 80,
        maxWidth: 80,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.vat || row.item?.vat),
    },
    {
      name: "Thành tiền",
      field: "",
      headerStyle: {
        minWidth: 120,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "right",
      },
      render: (row: any, index: number, stt: number) => {
        const quantity = isTransfer ? (parseFloat(row.accept) || 0) : (parseFloat(row.totalQuantity) || 0);
        const price = row.sellingPrice || 0;
        const vat = row.vat || 0;
        const result = quantity * price * (1 + vat / 100);
        return formatMoney(result);
      },
    },
    {
      name: "Số lô",
      field: "",
      headerStyle: {
        minWidth: 120,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (row: any, index: number, stt: number) => row.consignment,
    },
    {
      name: "Hạn sử dụng",
      field: "",
      headerStyle: {
        minWidth: 120,
        maxWidth: 120,
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => formatDateToDDMMYYYY(row.expiryDate),
    },
  ];

  return (
    <div className="spaces mt-12 border-table custom-table">
      <h4 className="spaces mt-8 ml-10 title">Danh sách vật tư</h4>
      <OctTable
        className={`${isTransfer
          ? "table-transfer"
          : (workFlow?.currId === STATUS_DIEU_CHUYEN.WAIT_IMPORT)
          ? "table-wait-import"
          : isView
          ? "table-view-dieu-chuyen"
          : "table-dieu-chuyen"
          } custom-header-table`}
        columns={columns}
        data={dsDieuChuyen}
        noToolbar
        clearToolbar
        noPagination
        fixedColumnsCount={-1}
      />
    </div>
  );
};

export default TableThongTinDieuChuyen;
