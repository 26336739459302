import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import InputSearch from "../../component/InputSearch";
import { Button } from "react-bootstrap";
import TextField from "../../component/TextField";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TablePagination } from "../../component/table/components/TablePagination";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { CODE, DEFAULT_PAGE_INDEX, KEY, SELECTION_MODE, WAREHOUSE_ID } from "../../utils/Constant";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import "../PhanHeXuatKho.scss";
import { EXPORT_COLUMNS } from "../constants/ColumnsXuatKho";
import { initialPhieuXuat, PhieuXuat } from "../models/XuatKhoModel";
import DialogThemMoiPhieuXuatKho from "./ThemMoiPhieuXuat/DialogThemMoiPhieuXuatKho";
import { AppContext } from "../../appContext/AppContext";
import { deletePhieuXuatKho, getDsThuocXuatKho, inPhieuXuatKhac, inPhieuXuatHuy, inPhieuXuatKhachHang, inPhieuXuatNCC, searchPhieuXuatKho, inPhieuXuatHaoPhi } from "../services/servicesXuatKho";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { toast } from "react-toastify";
import { PhanHeXuatKhoContext } from "../PhanHeXuatKho";
import WfStatusSummary from "../../workflows/components/WfStatusSummary";
import { getSummaryStatusByType } from "../../workflows/services/WfWorkflowsService";
import AutocompleteObjectV2 from "../../component/AutocompleteObjectV2";
import { EXPORT_OPTIONS, EXPORT_CODE, EXPORT_OPTIONS_KHAC, EXPORT_OPTIONS_HAO_PHI } from "../constants/constantsXuatKho";
import { formatDateFromDTOCustom, formatDateToDTOCustom } from "../../utils/FormatUtils"
import ButtonIn from "../../component/button-in-phieu";

type Props = {
  lstWarehouse: any;
  warehouseId: number;
}

export const FormXuatKho: FC<Props> = (props) => {
  const { warehouseId, lstWarehouse = [] } = props;
  const { setIsLoading } = useContext(AppContext);
  const { thongTinPhieu, setThongTinPhieu, setVatTuSelected, setRandomValue } = useContext(PhanHeXuatKhoContext);
  const [summaryStatus, setSummaryStatus] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [rowsPerPageDS, setRowsPerPageDS] = useState<number>(20);
  const [pageDS, setPageDS] = useState<number>(DEFAULT_PAGE_INDEX);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [configTableDsXuatKho, setConfigTableDsXuatKho] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  const [dsPhieuXuatKho, setDsPhieuXuatKho] = useState<any[]>([]);
  const [searchData, setSearchData] = useState<any>({});
  const [openDialogThemMoi, setOpenDialogThemMoi] = useState<any>(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
  const [dataEdit, setDataEdit] = useState<PhieuXuat>(initialPhieuXuat);
  const [rowSelected, setRowSelected] = useState<any>();
  const [warehouseExportType, setWarehouseExportType] = useState<any>();
  const [columns, setColumns] = useState<any>([])

  useEffect(() => {
    // debugger
    // if (2 === warehouseId) {
    setWarehouseExportType(EXPORT_OPTIONS[0])
  }, [warehouseId]);

  const getSelectedRowsData = async (row: any) => {
    try {
      setIsLoading(true);
      if (row?.length) {
        setRowSelected(row);
        const { data } = await getDsThuocXuatKho({ pageSize: rowsPerPageDS, pageIndex: pageDS, slipId: row[0].id });
        setThongTinPhieu({
          ...thongTinPhieu,
          dsThuoc: data?.data?.content,
        })
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const ConvertDate = (value?: any, name?: any) => {
    if (name == "doCreationFromDate") {
      return formatDateFromDTOCustom(value)
    } else if (name == "doCreationToDate") {
      return formatDateToDTOCustom(value)
    }
    return ""
  }

  const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await deletePhieuXuatKho(dataEdit?.id);
      if (CODE.SUCCESS === res.status) {
        toast.success("Xóa phiếu thành công");
        setOpenDialogConfirmDelete(false);
        updatePageData();
      }
    } catch (error: any) {
      const message = error?.message || "Có lỗi xảy ra, vui lòng thử lại";
      toast.error(message)
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setDataEdit(initialPhieuXuat);
    setThongTinPhieu(initialPhieuXuat)
    setVatTuSelected({});
    setOpenDialogConfirmDelete(false);
    setOpenDialogThemMoi(false);
    setRandomValue(Math.random());
    updatePageData();
  }

  const updatePageData = async () => {
    if (warehouseId === WAREHOUSE_ID.NHA_THUOC) {    // tường hợp kho nhà thuốc bắt buọc phải có warehouseExportType
      if (!warehouseExportType?.code) return
    }
    try {
      setIsLoading(true);
      const { data } = await searchPhieuXuatKho({
        ...searchData,
        keyword: searchData?.keyword?.trim() || null,
        doCreationFromDate: ConvertDate(searchData?.doCreationFromDate, "doCreationFromDate"),
        doCreationToDate: ConvertDate(searchData?.doCreationToDate, "doCreationToDate"),
        pageSize: rowsPerPage,
        pageIndex: page,
        warehouseId: warehouseId,
        type: warehouseExportType?.type,
        warehouseExportType: warehouseExportType?.code === EXPORT_CODE.KHACH_HANG ? null : warehouseExportType.code,
      });

      if (CODE.SUCCESS === data.code) {
        setDsPhieuXuatKho(data?.data?.content || []);
        setConfigTable({
          totalPages: (data.data.pageable.totalElements / data.data.pageable.pageSize).toFixed(),
          totalElements: data?.data?.pageable?.totalElements,
          // numberOfElements: data?.data?.pageable?.pageNumber,
        })
      }

      if (warehouseExportType?.tracker && warehouseExportType?.type) {
        const resSummary = await getSummaryStatusByType(
          warehouseExportType?.tracker,
          warehouseExportType?.type,
          {
            warehouseExportType: warehouseExportType?.code === EXPORT_CODE.KHACH_HANG ? null : warehouseExportType.code,
            warehouseId: warehouseId,
            fromDate: formatDateFromDTOCustom(searchData?.doCreationFromDate) || null,
            toDate: formatDateToDTOCustom(searchData?.doCreationToDate) || null,
          }
        );
        CODE.SUCCESS === resSummary?.data?.code && setSummaryStatus(resSummary.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSelectedRowsData(rowSelected);
  }, [rowsPerPageDS, pageDS]);

  useEffect(() => {
    updatePageData();
  }, [rowsPerPage, page, warehouseExportType])

  const handlerReturnPrint = (code?: any, slipId?: any) => {
    switch (code) {
      case EXPORT_CODE.NCC:
        return <ButtonIn
          isIcon
          fetchExport={inPhieuXuatNCC}
          params={{
            slipId: slipId,
          }}
        />
      case EXPORT_CODE.KHAC:
        return <ButtonIn
          isIcon
          fetchExport={inPhieuXuatKhac}
          params={{
            slipId: slipId,
          }}
        />
      case EXPORT_CODE.HUY:
        return <ButtonIn
          isIcon
          fetchExport={inPhieuXuatHuy}
          params={{
            slipId: slipId,
          }}
        />
      case EXPORT_CODE.HAO_PHI:
        return <ButtonIn
          isIcon
          fetchExport={inPhieuXuatHaoPhi}
          params={{
            slipId: slipId,
          }}
        />
      default:
        return <></>
    }
  }

  const columnsActions = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center gap-3">

        <>
          <CustomIconButton
            variant="edit"
            onClick={() => {
              setDataEdit(props.cell.row.original);
              setOpenDialogThemMoi(true);
            }}
          >
            <i className="bi bi-pencil-square text-navy"></i>
          </CustomIconButton>
          {props.cell.row.original?.color != "#28A745" &&
            <CustomIconButton
              variant="delete"
              onClick={() => {
                setDataEdit(props.cell.row.original);
                setOpenDialogConfirmDelete(true);
              }}
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          }
          {([EXPORT_CODE.NCC, EXPORT_CODE.HUY, EXPORT_CODE.KHAC, EXPORT_CODE.HAO_PHI].includes(warehouseExportType?.code)) &&
            handlerReturnPrint(warehouseExportType?.code, props.cell.row.original?.id)
            // <ButtonIn
            //   isIcon
            //   fetchExport={inPhieuXuatNCC}
            //   params={{
            //     slipId: props.cell.row.original?.id,
            //   }}
            // />
          }
        </>

      </div>
    )
  };

  const columnsActionsKH = {
    Header: (props: any) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thao tác"}
        className='text-center text-light min-w-80px'
      />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
      <div className="d-flex justify-content-center gap-3">
        <ButtonIn
          isIcon
          fetchExport={inPhieuXuatKhachHang}
          params={{
            slipId: props.cell.row.original?.id,
          }}
        />
      </div>
    )
  };

  const handleChangeSelect = (data: any) => {
    setWarehouseExportType(data)
    setSearchData({})
  };
  const handlerSearchKeyWorld = () => {
    setPage(DEFAULT_PAGE_INDEX);
    setRowsPerPage(20);
    updatePageData()
  }
  useEffect(() => {
    const columnsKey = warehouseExportType?.code as keyof typeof EXPORT_COLUMNS;
    setColumns(EXPORT_COLUMNS[columnsKey]);
  }, [warehouseExportType?.modelId]);

  return (

    <div className="bg-white h-100">
      <div className="flex-1 flex flex-column">
        <div className="d-flex bg-gray ">
          <div className="spaces width-100 bg-gray radius-2">
            <div className="flex bg-white spaces width-100 justify-content-between" >
              <div className="flex bg-white spaces width-100" >
                <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0  px-10">
                  Danh sách xuất kho
                </h4>
                <div className="flex bg-white spaces width-20 mt-2 ml-12" >
                  {warehouseId === WAREHOUSE_ID.NHA_THUOC &&
                    <AutocompleteObjectV2
                      options={[...EXPORT_OPTIONS]}
                      isSearchDefauilt={true}
                      name={"WmsCode"}
                      onChange={(options: any) => handleChangeSelect(options)}
                      value={warehouseExportType || ""}
                      className="autocomplete-custom-renderform  radius spaces width-100 h-29"
                    />
                  }
                  {warehouseId !== WAREHOUSE_ID.NHA_THUOC &&
                    <AutocompleteObjectV2
                      options={[
                        ...EXPORT_OPTIONS_KHAC,
                        ...(warehouseId !== WAREHOUSE_ID.NHA_THUOC ? EXPORT_OPTIONS_HAO_PHI : [])
                      ]}
                      isSearchDefauilt={true}
                      name={"WmsCode"}
                      onChange={(options: any) => handleChangeSelect(options)}
                      value={warehouseExportType || ""}
                      className="autocomplete-custom-renderform  radius spaces width-100 h-29"
                    />
                  }
                </div>
              </div>
            </div>
            <div className="d-flex mt-1 flex-column spaces width-100">
              <div className="bg-white">
                <div className="d-flex spaces pr-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                  <div className=" bg-white flex align-items-center ">
                    <Button
                      className="btn-fill ml-10 mx-2 mr-10 spaces min-w-90px btn btn-primary"
                      onClick={() => {
                        setDataEdit(initialPhieuXuat);
                        setThongTinPhieu({
                          ...thongTinPhieu,
                          dsThuoc: [],
                        })
                        setOpenDialogThemMoi(true);
                      }}
                    >
                      <i className="bi bi-plus-lg text-pri fs-3"></i>
                      Thêm
                    </Button>
                  </div>
                  <div className="d-flex spaces mx-10 ">
                    <TextField
                      label="Từ ngày"
                      name="doCreationFromDate"
                      type="date"
                      value={searchData?.doCreationFromDate || ""}
                      className="flex"
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="d-flex spaces mr-10">
                    <TextField
                      label="Đến ngày"
                      name="doCreationToDate"
                      type="date"
                      className="flex"
                      value={searchData.doCreationToDate || ""}
                      labelClassName="spaces mt-5 mr-5"
                      onChange={handleChangeSearchData}
                    />
                  </div>
                  <div className="flex-grow-1 ">
                    <InputSearch
                      placeholder="Tìm kiếm theo mã phiếu"
                      value={searchData.keyword || ""}
                      className="spaces h-31 pr-4 radius-3"
                      handleChange={(e) => setSearchData({ ...searchData, keyword: e.target.value })}
                      handleSearch={handlerSearchKeyWorld}
                      handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && handlerSearchKeyWorld()}
                    />
                  </div>
                </div>
                <div className=" spaces bg-white mb-0">
                  <TableCustom<PhieuXuat>
                    className="spaces h-bang-ds-phieu"
                    data={dsPhieuXuatKho}
                    columns={columns || []}
                    columnsActions={warehouseExportType?.code === EXPORT_CODE.KHACH_HANG ? columnsActionsKH : columnsActions}
                    verticalScroll={true}
                    selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                  // getSelectedRowsData={getSelectedRowsData}
                  />
                  <TablePagination
                    handlePagesChange={handlePagesChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsForPage={rowsForPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    totalPages={Number(configTable.totalPages)}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    hideTotalElements
                    customComponent={<WfStatusSummary summaryStatus={summaryStatus} col={2} />}
                    customComponentClassName="min-w-900px"
                    className="border border-0"
                  />
                </div>
              </div>
            </div>
            {/* <div className="d-flex mt-5 flex-column spaces bg-white width-100 ">
              <div className="flex bg-white  spaces width-100 " >
                <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0  px-10">
                  {`Thông tin xuất thuốc`}
                </h4>
              </div>
              <div className=" spaces bg-white mb-0">
                <TableCustom<ThongTinThuoc>
                  className="spaces h-bang-ds-xuat-thuoc table-view-ds-thuoc"
                  data={thongTinPhieu.dsThuoc || []}
                  columns={DanhSachXuatThuocColumns}
                  verticalScroll={true}
                />
                <TablePagination
                  handlePagesChange={handlePagesChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={rowsPerPageDS}
                  rowsForPage={rowsForPage}
                  page={pageDS}
                  setPage={setPageDS}
                  setRowsPerPage={setRowsPerPageDS}
                  totalPages={configTableDsXuatKho.totalPages}
                  totalElements={configTableDsXuatKho.totalElements}
                  numberOfElements={configTableDsXuatKho.numberOfElements}
                  className="border border-0"
                />
              </div>
            </div> */}
          </div>
          {openDialogThemMoi &&
            <DialogThemMoiPhieuXuatKho
              MATE_WH_DEFAUILT={lstWarehouse}
              warehouseExportType={warehouseExportType}
              show={openDialogThemMoi}
              onHide={handleCloseDialog}
              dataEdit={dataEdit}
              warehouseId={warehouseId}
            />
          }
          {openDialogConfirmDelete &&
            <ConfirmDialog
              show={openDialogConfirmDelete}
              onCloseClick={handleCloseDialog}
              onYesClick={handleDelete}
              title="Xóa phiếu xuất kho"
              message={`Bạn chắc chắn muốn xóa  phiếu: ${dataEdit.code} ?`}
              yes="Xóa"
              close="Hủy"
            />
          }
        </div>

      </div>

    </div>
  )
}

export default FormXuatKho;
