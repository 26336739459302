import { CellProps, Column } from "react-table";
import { formatDateToDDMMYYYY, formatMoney } from "../../utils/FormatUtils";

export const COLUMNS: Column<any>[] = [
	{
		Header: 'Mã Thuốc',
		accessor: 'code',
		width: 100,
	},
	{
		Header: 'Tên Thuốc',
		accessor: 'name',
		width: 200,
	},
	{
		Header: 'Số lô',
		accessor: 'consignment',
		width: 200,
	},
	{
		Header: 'Hàm Lượng',
		accessor: 'dosage',
		width: 150,
	},
	{
		Header: 'Hoạt Chất',
		accessor: 'actIngName',
		width: 150,
	},
	{
		Header: 'Đơn vị',
		accessor: 'unitofMeasureName',
		width: 120,
	},
	{
		Header: 'Khả dụng',
		accessor: 'availableQuantity',
		width: 80,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{value}</div>
			)
		},
	},
	{
		Header: 'Đơn giá',
		accessor: 'sellingPrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatMoney(value)}</div>
			)
		},
	},
	{
		Header: 'Giá bán',
		accessor: 'salePrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatMoney(value)}</div>
			)
		},
	},
	{
		Header: 'Giá BHYT',
		accessor: 'bhytPrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatMoney(value)}</div>
			)
		},
	},
	{
		Header: 'Giá VP',
		accessor: 'hospitalPrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatMoney(value)}</div>
			)
		},
	},
	{
		Header: 'Giá dịch vụ',
		accessor: 'servicePrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatMoney(value)}</div>
			)
		},
	},
	{
		Header: 'Loại Thuốc',
		accessor: 'drugTypeName',
		width: 100,
	},
	{
		Header: 'HSD',
		accessor: 'expiryDate',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-center">{formatDateToDDMMYYYY(value)}</div>
			)
		},
	},
];

export const COLUMNS_NHAP_KHO : Column<any>[] = [
	{
		Header: 'Mã Thuốc',
		accessor: 'code',
		width: 100,
	},
	{
		Header: 'Tên Thuốc',
		accessor: 'name',
		width: 200,
	},
	{
		Header: 'Số lô',
		accessor: 'consignment',
		width: 200,
	},
	{
		Header: 'Đơn vị',
		accessor: 'unitofMeasureName',
		width: 120,
	},
	{
		Header: 'Đơn giá',
		accessor: 'sellingPrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatMoney(value)}</div>
			)
		},
	},
	{
		Header: 'Giá BHYT',
		accessor: 'bhytPrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatMoney(value)}</div>
			)
		},
	},
	{
		Header: 'Giá VP',
		accessor: 'hospitalPrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatMoney(value)}</div>
			)
		},
	},
	{
		Header: 'Giá dịch vụ',
		accessor: 'servicePrice',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-end">{formatMoney(value)}</div>
			)
		},
	},
	{
		Header: 'QĐ thầu',
		accessor: 'serialNumber',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="">{value}</div>
			)
		},
	},
	{
		Header: 'HSD',
		accessor: 'expiryDate',
		width: 100,
		Cell: ({ value }: CellProps<any>) => {
			return (
				<div className="text-center">{formatDateToDDMMYYYY(value)}</div>
			)
		},
	},
];
