import { IconMenu } from "../../component/IconSvg";
import { CODE_FORM, TYPE_WH, TYPE_TU_TRUC } from "../../utils/Constant";

export const LIST_MENU_TT = (type: any) => [
  {
    id: CODE_FORM.PHIEU_LINH,
    type: TYPE_WH.PHIEU_LINH,
    name: 'Phiếu lĩnh',
    title: 'phiếu lĩnh',
  },
  {
    id: CODE_FORM.XUAT_SD,
    type: TYPE_WH.XUAT_SD,
    name: 'Xuất sử dụng, khoa phòng',
    title: 'phiếu xuất sử dụng khoa/phòng'
  },
  {
    id: CODE_FORM.BO_SUNG_CO_SO_TU_TRUC,
    type: TYPE_WH.BO_SUNG_CO_SO_TU_TRUC,
    name: 'Danh sách vật tư',
    title: type === OPTIONS_TYPE_TU_TRUC[0].code ? 'thuốc' : 'vật tư'
  },
  {
    id: CODE_FORM.NHAP_BU_CO_SO_TU_TRUC,
    type: TYPE_WH.NHAP_BU_CO_SO_TU_TRUC,
    name: 'Nhập bù cơ số tủ trực',
    title: 'nhập bù cơ số tủ trực'
  }
];

export const summaryStatus = [
  {
    statusName: 'Đã gửi yêu cầu',
    summary: 0,
    color: '#FF9C07',
  },
  {
    statusName: 'Đã xuất kho',
    summary: 0,
    color: '#17A2B8',
  },
  {
    statusName: 'Đã nhập kho',
    summary: 0,
    color: '#28A745',
  },
  {
    statusName: 'Đã kết thúc',
    summary: 0,
    color: '#6C757D',
  }
];

export const OPTIONS_TYPE_TU_TRUC = [
    { code: "TUTRUCTHUOC", name: "Tủ trực thuốc" },
    { code: "TUTRUCVATTU", name: "Tủ trực vật tư" }
]

export const TreeVatTuTuTruc = {
  code: "all",
  name: "",
  icon: <></>,
  filter: [
    {
      code: "TUTRUCTHUOC",
      name: "Danh sách", // thuốc
    },
    {
      code: "TUTRUCVATTU",
      name: "Danh sách", // Loại vật tư
    },
  ],
};

export const TU_TRUC = {
  BO_SUNG_CO_SO: 8,
  NHAP_BU_CO_SO: 9,
  XUAT_SU_DUNG: 12
}
