import { useFormikContext } from "formik";
import { Button, Form } from "react-bootstrap";
import GenerateFormComponent from "../../component/generate-form/GenerateFormHTMLNoValid";
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { generateInitValues, getSurplusPercent, roundToDecimalPlaces } from "../../utils/AppFunction";
import { AppContext } from "../../appContext/AppContext";
import DynamicComponent from "../../component/GenerateDynamicComponent";
import { TypeNhapKho } from "../models/NhapKhoModel";
import { CODE_NHAP_KHO, INIT_GENERAL_VALUES } from "../constants/constansNhapKho";
import { getListVatTuByCode } from "../services/services";
import { CODE } from "../../utils/Constant";
import { getDsThuocXuatKho } from "../../phan-he-xuat-kho/services/servicesXuatKho";
import { toast } from "react-toastify";
import { localStorageItem } from "../../utils/LocalStorage";
import { generateRandomId } from "../../utils/FormatUtils";

type Props = {
  LST_WAREHOUSE: any;
  listJsonGenerateVT?:any;
  generalValues: any;
  setGeneralFieldValue: any;
  indexRowEdit: number | null;
  setIndexRowEdit: Dispatch<SetStateAction<any>>;
  isView: boolean;
  warehouseId: number;
  type: TypeNhapKho;
  dsNhapKho: any[];
  setDsNhapKho: Dispatch<SetStateAction<any>>;
  initialValuesFormThemVT: any;
  setInitialValuesFormThemVT: Dispatch<SetStateAction<any>>;
  randomValues: number | undefined;
};

const FormThemVatTu = ({
  LST_WAREHOUSE,
  generalValues,
  listJsonGenerateVT,
  indexRowEdit,
  setIndexRowEdit,
  isView,
  warehouseId,
  type,
  dsNhapKho,
  setDsNhapKho,
  setGeneralFieldValue,
  initialValuesFormThemVT,
  setInitialValuesFormThemVT,
  randomValues,
}: Props) => {
  
  const { setIsLoading } = useContext(AppContext);
  const {
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormikContext<any>();

  const dataUser = localStorageItem.get("current-user") ? localStorageItem.get("current-user") : {};
  const isFirstRender = useRef(true);

  const handleTotalAmount = () => {
    const formData = values.themVatTuPhieuNhapKho || {};
    const price = parseFloat(type.code === CODE_NHAP_KHO.TRA_LE ? (formData.salePrice || 0) : (formData.sellingPrice || 0));
    const quantity = parseFloat(formData.totalQuantity || 0);
    const vat = parseFloat(formData.vat || 0);
    const priceVat = price * (1 + vat / 100);
    const discountRate = formData.discountRate || 0;
    const realPrice = price * (1 - discountRate / 100);
    const result = realPrice * quantity * (1 + vat / 100);
    const surpluspercent = getSurplusPercent(priceVat);

    const newFormData = {
      ...formData,
      realPrice: roundToDecimalPlaces(realPrice),
      totalAmount: roundToDecimalPlaces(result),
      surplus: priceVat ? surpluspercent : '',
    };
    setFieldValue("themVatTuPhieuNhapKho", newFormData);
  };

  useEffect(() => {
    handleTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.themVatTuPhieuNhapKho?.totalQuantity,
    values.themVatTuPhieuNhapKho?.hospitalPrice,
    values.themVatTuPhieuNhapKho?.salePrice,
    values.themVatTuPhieuNhapKho?.sellingPrice,
    values.themVatTuPhieuNhapKho?.discountRate,
    values.themVatTuPhieuNhapKho?.vat,
  ]);

  const handlePrice = () => {
    const formData = values.themVatTuPhieuNhapKho || {};
    const price = parseFloat((formData.sellingPrice || 0));
    const vat = parseFloat(formData.vat || 0);
    const priceVat = price * (1 + vat / 100);
    const surpluspercent = getSurplusPercent(priceVat);

    if (formData.isFirstClick || formData.isFirstChange) {
      setFieldValue("themVatTuPhieuNhapKho", {
        ...formData,
        isFirstClick: false,
        isFirstChange: false,
        surplus: surpluspercent,
      });
      return;
    };
    
    const surplus = priceVat * (surpluspercent / 100);
    const salePrice = surplus + priceVat;

    const newFormData = {
      ...formData,
      surplus: priceVat ? surpluspercent : '',
      salePrice: salePrice ? roundToDecimalPlaces(salePrice) : '',
      hospitalPrice: salePrice ? roundToDecimalPlaces(salePrice) : '',
      servicePrice: salePrice ? roundToDecimalPlaces(salePrice) : '',
    };
    setFieldValue("themVatTuPhieuNhapKho", newFormData);
  };

  useEffect(() => {
    handlePrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.themVatTuPhieuNhapKho?.sellingPrice,
    values.themVatTuPhieuNhapKho?.vat,
  ]);

  const getListVatTuKH = async () => {
    try {
      setIsLoading(true);
      const { data } = await getListVatTuByCode(generalValues.nhapKho.codeExport?.trim());
      if (CODE.SUCCESS === data?.code) {
        setGeneralFieldValue("nhapKho", {
          ...data.data,
          doCreation: new Date(),
          creator: dataUser.sub,
          code: generalValues.nhapKho?.code || null,
          id: generalValues.nhapKho?.id || null,
          mateWh: 'DR01',
          mateWhId: data.data.warehouseId,
          patientGender: data.data.patientGender || data.data.patientGenderName === 'Nam' ? 'G001' : 'G002',
          codeExport: generalValues.nhapKho.codeExport,
          codeExportIsValid: true,
        });

        const listVatTuResponse = await getDsThuocXuatKho({ slipId: data.data.id });
        if (CODE.SUCCESS === listVatTuResponse?.data?.code) {
          const lstVatTu = listVatTuResponse.data.data.content.map((item: any) => ({ ...item, id: null, codeExportIsValid: true, fakeId: generateRandomId() }))
          setDsNhapKho(lstVatTu);
        } else {
          toast.error('Lỗi tải danh sách thuốc/VT, vui lòng thử lại');
        }
      } else {

        const newLstVatTu = [...dsNhapKho].map(item => ({ ...item, codeExportIsValid: false, fakeId: generateRandomId() }));
        setDsNhapKho(newLstVatTu);
        setGeneralFieldValue("nhapKho", { ...generalValues.nhapKho, codeExportIsValid: false });
        toast.error(data.message)
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra, vui lòng thử lại');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // setFieldValue("themVatTuPhieuNhapKho", {});
    // if (generalValues.nhapKho.codeExport?.trim() && !isView) {
    //   const delayDebounceFn = setTimeout(() => {
    //     getListVatTuKH();
    //   }, 1000);
    //   return () => clearTimeout(delayDebounceFn);
    // } else {
    //   const newLstVatTu = [...dsNhapKho].map((item: any) => ({ ...item, codeExportIsValid: false }));
    //   setDsNhapKho(newLstVatTu);
    //   setGeneralFieldValue("nhapKho", {
    //     ...generalValues.nhapKho,
    //     codeExportIsValid: generalValues.nhapKho.id ? generalValues.nhapKho.codeExportIsValid : false
    //   });
    // }

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    };

    setIndexRowEdit(null);
    setFieldValue("themVatTuPhieuNhapKho", generateInitValues(listJsonGenerateVT));
    setGeneralFieldValue("nhapKho", {
      ...INIT_GENERAL_VALUES,
      ...LST_WAREHOUSE[warehouseId as keyof typeof LST_WAREHOUSE],
      id: generalValues.nhapKho.id,
      code: generalValues.nhapKho.code,
      codeExport: generalValues.nhapKho.codeExport,
      codeExportIsValid: false,
    });
    setDsNhapKho([]);
  }, [generalValues.nhapKho.codeExport]);

  useEffect(() => {
    if(generalValues.nhapKho.codeExport?.trim() && !isView && randomValues) getListVatTuKH();
  }, [randomValues]);

  useEffect(() => {
    if (listJsonGenerateVT.name !== "themvtnhapkhorhm") return;    
    setInitialValuesFormThemVT({
      ...generateInitValues(listJsonGenerateVT),
      serialNumber: values.themVatTuPhieuNhapKho?.serialNumber || "",
      biddingPackage: values.themVatTuPhieuNhapKho?.biddingPackage || "",
      biddingGroup: values.themVatTuPhieuNhapKho?.biddingGroup || "",
      biddingYear: values.themVatTuPhieuNhapKho?.biddingYear || "",
      supplier: generalValues?.nhapKho?.supplier
    });
    setIndexRowEdit(null);
    setDsNhapKho([]);
  }, [generalValues?.nhapKho?.supplier]);

  const handler = (data: any) => {
    
    setInitialValuesFormThemVT({
      ...initialValuesFormThemVT,
      ...data,
      biddingGroup: values.themVatTuPhieuNhapKho?.biddingGroup || "",
      biddingPackage: values.themVatTuPhieuNhapKho?.biddingPackage || "",
      isFirstChange: true,
    });
  };

  const handleFilterTableData = (lstData: any[], lstDataSelected: any[]) => {    
		return lstData.filter(item => {
      return !lstDataSelected.some(itemSelected => {
        const selectedId = itemSelected.itemId || itemSelected.item?.id;
        const itemId = item.itemId;
        return selectedId === itemId;
      });
    });
	};

  return (
    <Form onSubmit={handleSubmit} >
      <GenerateFormComponent
        isView={
          isView
          || !generalValues.nhapKho?.mateWh
          || (indexRowEdit === null && generalValues.nhapKho.codeExport)
        }
        isViewSelectThuoc={generalValues.nhapKho.codeExport}
        listFieldAuto={listJsonGenerateVT}
        handleChange={handleChange}
        setValues={setValues}
        values={values}
        touched={touched}
        errors={errors}
        propsRenderButton={handler}
        lstSelectedRenderButton={dsNhapKho}
        handleFilterDataRenderButton={listJsonGenerateVT.name === "themvtnhapkhorhm" ? handleFilterTableData : null}
        setFieldValue={setFieldValue}
        modelID={"themVatTuPhieuNhapKho"}
        DynamicClass={"pl-38"}
        customComponent={DynamicComponent}
        warehouseId={warehouseId}
        isNhapKho={[CODE_NHAP_KHO.NHA_CUNG_CAP, CODE_NHAP_KHO.KHAC].includes(type.code)}
        customFieldReadOnly={
          listJsonGenerateVT.name === "themvtnhapkhorhm"
          ? {
            serialNumber: indexRowEdit !== null,
            biddingYear: indexRowEdit !== null,
          } : {}
        }
      />
      <div className="spaces mt-10">
        {(
          !isView
          && (!!generalValues.nhapKho?.receWarehouse || !!generalValues.nhapKho?.mateWh)
          && (indexRowEdit !== null || !generalValues.nhapKho.codeExport)
        ) &&
          <Button className="btn btn-fill spaces min-w-90" onClick={e => handleSubmit(values)}>
            {indexRowEdit === null ? "Thêm thuốc/VT" : "Cập nhật thuốc/VT"}
          </Button>
        }
      </div>
    </Form>
  );
};

export default FormThemVatTu;
