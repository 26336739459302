import { ChangeEvent, ReactNode, useContext, useEffect, useState } from "react";
import InputSearch from "../../InputSearch";
import { AppContext } from "../../../appContext/AppContext";
import { KEY } from "../../autocompleteOct/models";
import { TableCustom } from "../../table/table-custom/TableCustom";
import { CODE, DEFAULT_PAGE_INDEX, SELECTION_MODE } from "../../../utils/Constant";
import { TablePagination } from "../../table/components/TablePagination";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../../utils/PageUtils";
import { columnsDSVatTu } from "../const/Columns";
import { searchVatTu } from "../QuanLyKhoServices";
import { toast } from "react-toastify";
import "../styles.scss"
import { Col, Form, Row } from "react-bootstrap";
import CustomDropdownButtonChoose from "../../CustomDropdownButtonChoose";
import SelectTree from "../../SelectTree";
import { IconMenu } from "../../IconSvg";
import { TreeVatTu } from "../../../phan-he-thong-ke/constants/ColumnsThongKe";
import { getListNhomVatTu } from "../../../phan-he-thong-ke/services/ThongKeServices";

interface TableDSVatTuProps {
    warehouseId: number | undefined;
    customTitle?: ReactNode;
}

const TableDSVatTu: React.FC<TableDSVatTuProps> = (props) => {
    const { warehouseId, customTitle } = props;
    const { setIsLoading } = useContext(AppContext);
    const [dsVatTu, setDsVatTu] = useState([]);
    const [searchData, setSearchData] = useState<any>({ isNoZero: false });
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
    const [listNhomVatTu, setListNhomVatTu] = useState([]);
    const [nhomVatTu, setNhomVatTu] = useState<any>({});
    const [treeData, setTreeData] = useState<any>(TreeVatTu);
    const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
    const [idSelected, setIdSelected] = useState<string>("");
    const [configTable, setConfigTable] = useState<any>({
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
    });

  const handleConvertTreeData = (data: any[]) => {
    let convertedData: any[] = data?.map((item, index) => {
      return {
        ...item,
        code: item?.code || item?.name,
        name: item?.name,
        ...(item?.subs &&
          item?.subs?.length && {
            filter: handleConvertTreeData(item?.subs || []),
          }),
      };
    });
    return convertedData;
  };

  const handlerClickItem = (item: any) => {
    setNhomVatTu(item);
    setSearchData({...searchData, materialType: null});
    let treeDataConverted = {
      code: "all",
      name: "Tất cả dịch vụ",
      icon: <IconMenu />,
      filter: handleConvertTreeData(item?.terms || []),
    };
    setTreeData(treeDataConverted);
  };

  const handleGetSelectedItem = (data: any) => {
    setSearchData({
      ...searchData,
      materialType: data?.code === searchData.materialType ? null : data?.code,
    });
  };

    const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value,
        });
    };
    const handlerSearchKeyWorld = () => {
        setPage(DEFAULT_PAGE_INDEX);
        setRowsPerPage(20);
        updatePageData()
    }
    const updatePageData = async () => {
        try {
            setIsLoading(true);
            const { data } = await searchVatTu({...searchData, materialGroup: nhomVatTu.id, pageIndex: page, pageSize: rowsPerPage, warehouseId });
            if (CODE.SUCCESS === data.code) {
                setDsVatTu(data.data.content);
                setConfigTable({
                    totalPages: (data.data.pageable.totalElements / data.data.pageable.pageSize).toFixed(),
                    totalElements: data?.data?.pageable?.totalElements,
                    // numberOfElements: data?.data?.pageable?.pageNumber,
                })
            } else {
                toast.error('Có lỗi xảy ra, vui lòng thử lại');
            };
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getListVatTu = async () => {
        try {
            setIsLoading(true);
            let { data } = await getListNhomVatTu({});
            let ListData = data?.data?.map((item: any) => {
                return {
                ...item,
                name: "Nhóm vật tư - " + item?.name,
                defauiltName: item?.name,
                }
            })
            setListNhomVatTu(ListData);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getListVatTu();
    }, []);

    useEffect(() => {
        if ((warehouseId || warehouseId === 0) && nhomVatTu?.id) {
            updatePageData();
        }
    }, [page, rowsPerPage, warehouseId, searchData.isNoZero, searchData.materialType, nhomVatTu?.id]);
    
    return (
        <Row>
            <Col xl={2} xs={3}>
                <div className="spaces mr-5 bg-white h-calc-vh-130 radius-2">
                    <div className="spaces px-10 pt-4 h-40">
                        <CustomDropdownButtonChoose
                            drop="down"
                            listMenuItems={listNhomVatTu}
                            onClickItem={handlerClickItem}
                            label="Chọn nhóm vật tư"
                            className="spaces w-100 ml-1"
                            menuItemsClassName="w-100 mx-6"
                        />
                    </div>
                    <SelectTree
                        className="w-100 h-100 border px-0 border-0 overflow-auto"
                        codeCollapses={codeCollapses}
                        handleChangeCollapsesCode={setCodeCollapses}
                        idSelected={idSelected}
                        handleChangeSelectId={setIdSelected}
                        getSelectedItem={handleGetSelectedItem}
                        selectTree={treeData}
                    />
                </div>
            </Col>
            <Col xl={10} xs={9}>
            <div className="bg-white h-100">
                <div className="flex-1 flex flex-column">
                    <div className="d-flex bg-gray ">
                        <div className="spaces width-100 bg-gray radius-2">
                            <div className="flex justify-content-between bg-white spaces width-100 " >
                                <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10 flex gap-6">
                                    {customTitle ? customTitle : `Danh sách ${nhomVatTu?.defauiltName || ''}`}
                                </h4>
                            </div>
                            <div className="d-flex  mt-1 flex-column spaces width-100">
                                <div className="bg-white">
                                    <div className="d-flex spaces px-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                                        <Form.Check
                                            label="Ẩn thuốc/vật tư có tồn = 0"
                                            className="spaces mr-20"
                                            onChange={(e) => setSearchData({ ...searchData, isNoZero: e.target.checked })}
                                            checked={searchData.isNoZero}
                                        />
                                        <div className="flex-grow-1 ">
                                            <InputSearch
                                                name="keyword"
                                                value={searchData?.keyword || ''}
                                                placeholder="Tìm kiếm theo mã hoặc tên thuốc/vật tư"
                                                handleChange={handleChangeSearchData}
                                                handleSearch={() => handlerSearchKeyWorld()}
                                                handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && handlerSearchKeyWorld()}
                                                className="spaces h-31 pr-4  radius-3"
                                            />

                                        </div>
                                    </div>
                                    <div className=" spaces bg-white mb-0">
                                        <TableCustom<any>
                                            className="spaces h-bang-ds-vat-tu-ton-kho"
                                            data={dsVatTu}
                                            columns={columnsDSVatTu}
                                            verticalScroll={true}
                                            selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                                        />
                                        <TablePagination
                                            handlePagesChange={handlePagesChange}
                                            handleRowsPerPageChange={handleRowsPerPageChange}
                                            rowsPerPage={rowsPerPage}
                                            rowsForPage={rowsForPage}
                                            page={page}
                                            setPage={setPage}
                                            setRowsPerPage={setRowsPerPage}
                                            totalPages={Number(configTable.totalPages)}
                                            totalElements={configTable.totalElements}
                                            numberOfElements={configTable.numberOfElements}
                                            className="border border-0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Col>
        </Row>
    );
};

export default TableDSVatTu;