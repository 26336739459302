import { ChangeEvent, useContext, useEffect, useState } from "react";
import InputSearch from "../../component/InputSearch";
import { AppContext } from "../../appContext/AppContext";
import { KEY } from "../../component/autocompleteOct/models";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { CODE, DEFAULT_PAGE_INDEX, SELECTION_MODE } from "../../utils/Constant";
import { TablePagination } from "../../component/table/components/TablePagination";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import { columnDM } from "../const/Columns";
import { searchList, deleteDanhMuc } from "../DanhMucServices";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import LabelRequired from "../../component/LabelRequired";
import AutocompleteObjectV2 from '../../component/AutocompleteObjectV2';
import { searchListDM } from "../DanhMucServices"
import DialogThemDanhMuc from "./DialogThemDanhMuc";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { generateForm, generateInitValues } from "../../utils/AppFunction";
import { localStorageItem } from "../../utils/LocalStorage";

interface TableDSVatTuProps {}

const TableDSVatTu: React.FC<TableDSVatTuProps> = (props) => {
    const dataUser = localStorageItem.get("current-user") || {};
    const { setIsLoading } = useContext(AppContext);
    const [dsVatTu, setDsVatTu] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
    const [listDM, setListDM] = useState<any[]>();
    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState<boolean>(false);
    const [dataEdit, setDataEdit] = useState<any>();
    const [keyword, setKeyWord] = useState<any>();
    const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
    const [columns, setColumns] = useState<any>(columnDM["NCC"]);
    const [openDialogThemMoi, setOpenDialogThemMoi] = useState<any>(false);
    const [initValues, setInitValues] = useState<any>({});
    const [configTable, setConfigTable] = useState<any>({
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
    });
    const [searchObj, setSearchObj] = useState<any>({
        pageSize: 20,
        pageIndex: 1,
    });
    const handlerSearchKeyWorld = () => {
        setPage(DEFAULT_PAGE_INDEX);
        setRowsPerPage(20);
        updatePageData()
      }
    const COLUMNS = {
        formdanhmucncc: columnDM['NCC'],
        formdanhmuchangsx: columnDM['HSX'],
        formdanhmucnuocsx: columnDM['NSX'],
        dmthemdonvitinh: columnDM['DVT'],
        dmthemduongdung: columnDM['DD'],
        dmthemhoatchat: columnDM['HC'],
        danhmucgoithau: columnDM['THAU'],
    }
    useEffect(() => {
        searchObj?.code && updateForm();
    }, [searchObj]);
    const updateForm = async () => {
        const data = await generateForm(searchObj?.code, setIsLoading);
        setInitValues({
            ...generateInitValues(data),
            doCreation: new Date(),
            creator: dataUser?.sub,
        });
        setListJsonGenerate(data || []);
    };
    useEffect(() => {
        getListKho();
    }, [])
    const getListKho = async () => {
        try {
            setIsLoading(true);
            let { data } = await searchListDM();
            setListDM(data?.data?.content);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleCloseDialogDelete = () => {
        updatePageData();
        setDataEdit({});
        setOpenDialogConfirmDelete(false);
        setOpenDialogThemMoi(false);
    };
    const handlerClose = () => {
        setInitValues({
            ...generateInitValues(listJsonGenerate),
            doCreation: new Date(),
            creator: dataUser?.sub,
        });
        setDataEdit({});
        setOpenDialogThemMoi(false);
        updatePageData()
    }
    const handlerOpen = () => {
        if (!searchObj?.code) return toast.error('Vui lòng chọn danh mục');
        setOpenDialogThemMoi(true);
    }
    const updatePageData = async () => {
        if (!searchObj?.code) return;
        setDsVatTu([]);
        try {
            setIsLoading(true);
            const { data } = await searchList(listJsonGenerate?.path, {
                ...searchObj,
                keyword: keyword,
                pageSize: rowsPerPage,
                pageIndex: page,
            });
            if (CODE.SUCCESS === data.code) {
                setDsVatTu(data.data?.content);
                setConfigTable({
                    totalPages: (data.data.pageable.totalElements / data.data.pageable.pageSize).toFixed(),
                    totalElements: data?.data?.pageable?.totalElements,
                })
            } else {
                toast.error('Có lỗi xảy ra, vui lòng thử lại');
            };
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const modelIdKey = searchObj?.code as keyof typeof COLUMNS;
        if (searchObj?.code) {
            updatePageData();
            setColumns(COLUMNS[modelIdKey])
        }
    }, [page, rowsPerPage, listJsonGenerate?.path]);

    const handleChangeSelect = (options?: any) => {
        handlerSearchKeyWorld()
        setKeyWord("")
        setSearchObj({
            ...searchObj,
            code: options?.code,
            name: options?.name,
            id: options?.id,
            description: JSON.parse(options.description),
        })
    };

    const handleChangeSearchData = (e: ChangeEvent<HTMLInputElement>) => {
        setKeyWord(e.target.value)
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            const res = await deleteDanhMuc(listJsonGenerate?.path, dataEdit);
            if (CODE.SUCCESS === res.status) {
                toast.success("Xóa phiếu thành công");
                setDataEdit({});
                setOpenDialogConfirmDelete(false);
                updatePageData();
            }
        } catch (error: any) {
            const message = error?.message || "Có lỗi xảy ra, vui lòng thử lại";
            toast.error(message);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const columnsActions = {
        Header: (props: any) => (
            <TableCustomHeader<any>
                tableProps={props}
                title={"Thao tác"}
                className='text-center text-light w-80px'
            />
        ),
        id: 'actions',
        Cell: ({ ...props }) => (
            <div className="d-flex justify-content-center gap-3 w-80px">

                <>
                    <CustomIconButton
                        variant="edit"
                        onClick={() => {
                            setDataEdit(props.cell.row.original);
                            setOpenDialogThemMoi(true);
                        }}
                    >
                        <i className="bi bi-pencil-square text-navy"></i>
                    </CustomIconButton>
                    {(props.cell.row.original?.color != "#28A745" && !props.cell.row.original?.isWarehouseReceipt) &&
                        <CustomIconButton
                            variant="delete"
                            onClick={() => {
                                setDataEdit(props.cell.row.original);
                                setOpenDialogConfirmDelete(true);
                            }}
                        >
                            <i className="bi bi-trash3-fill text-danger"></i>
                        </CustomIconButton>
                    }

                </>

            </div>
        )
    };
    
    return (
        <div className="bg-white h-100">
            <div className="flex-1 flex flex-column">
                <div className="d-flex bg-gray ">
                    <div className="spaces width-100 bg-gray radius-2">
                        <div className="flex justify-content-between bg-white spaces width-100 " >

                        </div>
                        <div className="d-flex  mt-1 flex-column spaces width-100">
                            <div className="bg-white">
                                <div className="d-flex spaces px-8 bg-white  align-items-center mb-6">
                                    <div className="d-flex spaces mt-6">
                                        <LabelRequired className="spaces min-w-80" label={"Danh mục"} />
                                    </div>
                                    <div className="d-flex">
                                        <AutocompleteObjectV2
                                            options={listDM || []}
                                            isSearchDefauilt={true}
                                            name={"WmsCode"}
                                            onChange={(options: any) => handleChangeSelect(options)}
                                            className="autocomplete-custom-renderform mt-6 pl-5 radius spaces min-w-200 h-29"
                                            isClearable={false}
                                        />
                                    </div>
                                    <div className="d-flex">
                                        <Button className="btn-fill mt-5 ml-10 spaces min-w-90px btn btn-primary" onClick={handlerOpen}>
                                            <i className='bi bi-plus-lg text-pri fs-3'></i>
                                            Thêm
                                        </Button>
                                    </div>
                                </div>
                                <div className="d-flex spaces px-8 mt-6 bg-white justify-content-between align-items-center mb-6">
                                    <div className="flex-grow-1 ">
                                        <InputSearch
                                            name="keyword"
                                            value={keyword || ''}
                                            placeholder="Tìm kiếm theo mã hoặc tên"
                                            handleChange={handleChangeSearchData}
                                            handleSearch={handlerSearchKeyWorld}
                                            handleKeyDown={(e: React.KeyboardEvent) => e.key === KEY.ENTER && handlerSearchKeyWorld()}
                                            className="spaces h-31 pr-4  radius-3"
                                        />
                                    </div>
                                </div>
                                <div className=" spaces bg-white mb-0">
                                    <TableCustom<any>
                                        className="spaces h-calc-vh-180"
                                        data={dsVatTu}
                                        columns={columns}
                                        columnsActions={columnsActions}
                                        verticalScroll={true}
                                        selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                                    />
                                    <TablePagination
                                        handlePagesChange={handlePagesChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        rowsPerPage={rowsPerPage}
                                        rowsForPage={rowsForPage}
                                        page={page}
                                        setPage={setPage}
                                        setRowsPerPage={setRowsPerPage}
                                        totalPages={Number(configTable.totalPages)}
                                        totalElements={configTable.totalElements}
                                        numberOfElements={configTable.numberOfElements}
                                        className="border border-0"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openDialogThemMoi &&
                <DialogThemDanhMuc
                    dataEdit={dataEdit}
                    listJsonGenerate={listJsonGenerate}
                    onHide={() => handlerClose()}
                    item={searchObj}
                    show={openDialogThemMoi}
                    initialValues={initValues}
                />
            }
            {openDialogConfirmDelete && (
                <ConfirmDialog
                    show={openDialogConfirmDelete}
                    onCloseClick={handleCloseDialogDelete}
                    onYesClick={handleDelete}
                    title="Xóa danh mục"
                    message={`Bạn chắc chắn muốn xóa danh mục: ${dataEdit.name || dataEdit.decisionNumber} ?`}
                    yes="Xóa"
                    close="Hủy"
                />
            )}
        </div>
    );
};

export default TableDSVatTu;