import axios, { AxiosResponse } from "axios";
import { WfSummaryStatusRes, WfWorkflowsReq, WfWorkflowsRes } from "../models/WfWorkflows";
import { OctResponse } from "../../models/OctResponse";
import { localStorageItem } from '../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";

const BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_WMS_API_URL;
const API_PATH_WORKFLOWS = BASE_URL + "/wf-workflows";

export const getWfWorkflows = (trackerCode: string, objectId: string | undefined, type?: number | undefined) => {
  let url = ` ${API_PATH_WORKFLOWS}?tracker=${trackerCode}`;
  if (objectId) url += `&objectId=${objectId}`;
  if(type) url += `&type=${type}`

  return axios.get(url);
}
export const updateWfWorkflows = (trackerCode: string, wfWorkflowsReq: WfWorkflowsReq): Promise<AxiosResponse<OctResponse<WfWorkflowsRes>>> => {
  let url = ` ${API_PATH_WORKFLOWS}/${trackerCode}`
  return axios.post(url, wfWorkflowsReq);
};

export const getSummaryStatusByTracker = (trackerCode: string): Promise<AxiosResponse<OctResponse<WfSummaryStatusRes[]>>> => {
  let url = `${API_PATH_WORKFLOWS}/${trackerCode}/status/summary`
  return axios.get(url);
}

// export const getSummaryStatusByType = (trackerCode: string, type: number | null, warehouseExportType?: any, warehouseId?: number): Promise<AxiosResponse<OctResponse<WfSummaryStatusRes[]>>> => {
//   let url = `${BASE_URL}/slip/${trackerCode}/status/summary/${type}`
//   return axios.get(url, { params: { warehouseExportType: warehouseExportType?.code || null, warehouseId: warehouseId } });
// }

export const getSummaryStatusByType = (trackerCode: string, type: number | null, params?: any): Promise<AxiosResponse<OctResponse<WfSummaryStatusRes[]>>> => {
  let url = `${BASE_URL}/slip/${trackerCode}/status/summary/${type}`
  return axios.get(url, { params });
};

export const getSummaryStatusByIdWarehouse = (trackerCode: string, type: number, idWarehouse: number, whIssueId?: number): Promise<AxiosResponse<OctResponse<WfSummaryStatusRes[]>>> => {
  let url = `${BASE_URL}/slip/${trackerCode}/status/summary/${type}`
  let objParams = { warehouseId: idWarehouse } as {
    warehouseId: number;
    whIssueId: number;
  };
  if (whIssueId) {
    objParams = { ...objParams, whIssueId: whIssueId };
  }
  return axios.get(url, { params: objParams });
}
