import * as Yup from "yup";
import { Formik } from "formik";
import FormDieuChuyen from "./components/FormDieuChuyen";
import { FC, SetStateAction, createContext, useState, Dispatch, useEffect } from "react";
import { getListWarehouse } from "../utils/ServicesUtils";

export interface PhanHeDieuChuyenContextProps {
  dsDieuChuyen: any;
  setDsDieuChuyen: Dispatch<SetStateAction<any>>;
  onlyView: boolean;
  setOnlyView: Dispatch<SetStateAction<boolean>>;
}

const initialContext = {
  dsDieuChuyen: [],
  setDsDieuChuyen: () => {},
  onlyView: false,
  setOnlyView: () => {},
};

export const PhanHeDieuChuyenContext = createContext<PhanHeDieuChuyenContextProps>(initialContext);

type Props = {
  warehouseId: number,
}

export const PhanHeDieuChuyen: FC<Props> = (props) => {
  const { warehouseId } = props;
  const [dsDieuChuyen, setDsDieuChuyen] = useState([]);
  const [onlyView, setOnlyView] = useState(false);
  const [lstWarehouse, setLstWarehouse] = useState<any>({});
  const validationSchema = Yup.object({});

  const updateData = async () => {
    try {
      const data = await getListWarehouse();
      let newLstData: any = {};
      data.forEach((item: any) => {
        newLstData[item.id] = {
          reqWhId: item.id,
          reqWhName: item.name,
          reqWh: item.code,
        };
      });
      setLstWarehouse(newLstData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  return (
    <Formik<any>
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      <PhanHeDieuChuyenContext.Provider
        value={{
          dsDieuChuyen: dsDieuChuyen,
          setDsDieuChuyen: setDsDieuChuyen,
          onlyView,
          setOnlyView,
        }}
      >
        <div className="bg-white">
          <div className="flex-1 flex flex-column">
            <div className="py-1">
              <FormDieuChuyen warehouseId={warehouseId} LST_WAREHOUSE={lstWarehouse}/>
            </div>
          </div>
        </div>
      </PhanHeDieuChuyenContext.Provider>
    </Formik>
  );
};

export default PhanHeDieuChuyen;
