import { ChangeEvent, ReactNode, useContext, useEffect, useState } from "react";
import InputSearch from "../../component/InputSearch";
import { AppContext } from "../../appContext/AppContext";
import { KEY } from "../../component/autocompleteOct/models";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { CODE, DEFAULT_PAGE_INDEX, SELECTION_MODE } from "../../utils/Constant";
import { TablePagination } from "../../component/table/components/TablePagination";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import { columnsDSVatTu } from "../const/Columns";
import { searchVatTu } from "../TheKhoServices";
import { toast } from "react-toastify";
import { Button, FormCheck } from "react-bootstrap";
import TextField from "../../component/TextField";
import SelectTableButton from "../../../modules/component/select-thuoc-button"
import LabelRequired from "../../component/LabelRequired";
import AutocompleteObjectV2 from '../../component/AutocompleteObjectV2';
import { searchListKho, inPhieuTheKho } from "../TheKhoServices"
import { formatDateFromDTOCustom, formatDateDTO, formatDateToDTOCustom } from "../../utils/FormatUtils"
import ButtonIn from "../../component/button-in-phieu";
import DatePickerCustom from "../../component/date-picker/DatePickerCustom";


interface TableDSVatTuProps {
    warehouseId: number | undefined;
    customTitle?: ReactNode;
}

const TableDSVatTu: React.FC<TableDSVatTuProps> = (props) => {
    const { warehouseId, customTitle } = props;
    const { setIsLoading } = useContext(AppContext);
    const [dsVatTu, setDsVatTu] = useState([]);
    const [searchData, setSearchData] = useState<any>();
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
    const [listKho, setListKho] = useState<any[]>();
    const [dataPrint, setDataPrint] = useState<any>();
    const [openDialogThemMoi, setOpenDialogThemMoi] = useState<any>(false);
    const [configTable, setConfigTable] = useState<any>({
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
    });
    const [searchObj, setSearchObj] = useState<any>({
        doCreationFromDate: "",
        doCreationToDate: "",
        pageSize: 20,
        pageIndex: 1,
        type: "",
        warehouseId: "",
        itemId: ""
    });


    useEffect(() => {
        setDataPrint({})
        getListKho();
    }, [])
    const getListKho = async () => {
        try {
            setIsLoading(true);
            let { data } = await searchListKho({
                pageIndex: 1,
                pageSize: 1000,
                // warehouseCode: values?.dieuChuyen?.requestWh,
            });
            setListKho(data?.data?.content);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeSearchData = (name?: any, value?: any) => {
        setPage(DEFAULT_PAGE_INDEX);
        setRowsPerPage(20);
        setSearchObj({
            ...searchObj,
            [name]: value
        })
    };
    const ConvertDate = (value?: any, name?: any) => {
        if (name == "doCreationFromDate") {
            return formatDateFromDTOCustom(value)
        } else if (name == "doCreationToDate") {
            return formatDateToDTOCustom(value)
        }
        return ""
    }
    const updatePageData = async () => {
        try {
            setIsLoading(true);

            const { data } = await searchVatTu({
                ...searchObj,
                pageSize: rowsPerPage,
                pageIndex: page,
                doCreationFromDate: formatDateDTO(searchObj?.doCreationFromDate),
                doCreationToDate: formatDateDTO(searchObj?.doCreationToDate)
            });
            if (CODE.SUCCESS === data.code) {
                setDsVatTu(data?.data?.content);
                setConfigTable({
                    totalPages: (data.data.pageable.totalElements / data.data.pageable.pageSize).toFixed(),
                    totalElements: data?.data?.pageable?.totalElements,
                    // numberOfElements: data?.data?.pageable?.pageNumber,
                })
            } else {
                toast.error('Có lỗi xảy ra, vui lòng thử lại');
            };
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (searchObj?.warehouseId && searchObj?.itemId) {
            updatePageData();
        }
    }, [page, rowsPerPage, searchObj]);
    const handleChangeSelect = (options?: any) => {
        setSearchObj({
            doCreationFromDate: "",
            doCreationToDate: "",
            pageSize: 20,
            pageIndex: 1,
            type: "",
            itemId: "",
            warehouseId: options?.id
        })
        setDsVatTu([]);
    };

    const handler = (values?: any) => {
        setPage(DEFAULT_PAGE_INDEX);
        setRowsPerPage(20);
        setDataPrint({
            ...values,
            itemId: values?.id
        })
        setSearchObj({
            ...searchObj, itemId: values?.id, name: values?.name, serialNumber: values?.serialNumber, consignment: values?.consignment
        })
    }

    return (
        <div className="bg-white h-100">
            <div className="flex-1 flex flex-column">
                <div className="d-flex bg-gray ">
                    <div className="spaces width-100 bg-gray radius-2">
                        <div className="flex justify-content-between bg-white spaces width-100 " >

                        </div>
                        <div className="d-flex  mt-1 flex-column spaces width-100">
                            <div className="bg-white">
                                <div className="d-flex spaces px-8 bg-white  align-items-center mb-6">
                                    <div className="d-flex spaces mt-6">
                                        <LabelRequired className="spaces min-w-120" label={"Kho thuốc/Vật tư"} />
                                    </div>
                                    <div className="d-flex">
                                        <AutocompleteObjectV2
                                            options={listKho || []}
                                            isSearchDefauilt={true}
                                            name={"WmsCode"}
                                            onChange={(options: any) => handleChangeSelect(options)}
                                            // value={warehouseExportType || ""}
                                            className="autocomplete-custom-renderform mt-6 pl-5 radius spaces min-w-200 h-29"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex spaces px-8 bg-white justify-content-between align-items-center mb-6">
                                    <div className="d-flex spaces mt-6">
                                        <LabelRequired className="spaces min-w-120" label={"Thuốc/Vật tư"} />
                                    </div>
                                    <div className="flex-grow-1">
                                        <SelectTableButton
                                            values={searchObj}
                                            setThuocSelected={handler}
                                            DynamicClass="pl-5"
                                            warehouseId={searchObj?.warehouseId}
                                        />
                                    </div>
                                    {/* <div className="d-flex spaces mx-10 mt-6">
                                        <FormCheck
                                            key={"gopThuoc"}
                                            type="checkbox"
                                            label={"Gộp thuốc/VT cùng mã"}
                                            className="d-flex align-items-center spaces gap-7 mt-4"
                                            checked={true}
                                        // onChange={(e) =>
                                        //     handleTypeCheckChange(check.type, e.target.checked)
                                        // }
                                        />
                                    </div> */}
                                    <div className="d-flex align-items-center spaces mx-10 mt-8">
                                        <LabelRequired className="min-w-65px fw-bold" label="Từ ngày" />
                                        {/* <TextField
                                            label="Từ ngày"
                                            name="doCreationFromDate"
                                            type="datetime-local"
                                            value={searchObj?.doCreationFromDate || ""}
                                            className="flex"
                                            labelClassName="spaces mt-4 mr-5"
                                            onChange={(e: any) => handleChangeSearchData(e.target.name, e.target.value)}
                                        /> */}
                                        <DatePickerCustom
                                            name='doCreationFromDate'
                                            value={searchObj?.doCreationFromDate || ""}
                                            setDateValue={date => {
                                                handleChangeSearchData("doCreationFromDate", date);
                                            }}
                                            mode="date-time"
                                        // dateFormatOutput="DD-MM-YYYY"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center spaces mx-10 mt-8">
                                        <LabelRequired className="min-w-65px fw-bold" label="Đến ngày" />
                                        <DatePickerCustom
                                            name='doCreationToDate'
                                            value={searchObj?.doCreationToDate || ""}
                                            setDateValue={date => {
                                                handleChangeSearchData("doCreationToDate", date);
                                            }}
                                            mode="date-time"
                                        // dateFormatOutput="DD-MM-YYYY"
                                        />
                                    </div>
                                    <div className="d-flex spaces mr-10 mt-6">
                                        <ButtonIn
                                            fetchExport={inPhieuTheKho}
                                            params={{
                                                ...dataPrint,
                                                pageSize: rowsPerPage,
                                                pageIndex: page,
                                                warehouseId: searchObj?.warehouseId,
                                                doCreationFromDate: formatDateDTO(searchObj?.doCreationFromDate),
                                                doCreationToDate: formatDateDTO(searchObj?.doCreationToDate)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className=" spaces bg-white mb-0">
                                    <TableCustom<any>
                                        className="spaces h-calc-vh-190"
                                        data={dsVatTu}
                                        columns={columnsDSVatTu}
                                        verticalScroll={true}
                                        selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                                    />
                                    <TablePagination
                                        handlePagesChange={handlePagesChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        rowsPerPage={rowsPerPage}
                                        rowsForPage={rowsForPage}
                                        page={page}
                                        setPage={setPage}
                                        setRowsPerPage={setRowsPerPage}
                                        totalPages={Number(configTable.totalPages)}
                                        totalElements={configTable.totalElements}
                                        numberOfElements={configTable.numberOfElements}
                                        className="border border-0"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableDSVatTu;