import { Formik } from "formik";
import { Dispatch, FC, SetStateAction, createContext, useEffect, useState } from "react";
import FormXuatKho from "./components/FormXuatKho";
import { initialPhieuXuat, PhieuXuat } from "./models/XuatKhoModel";
import { getListWarehouse } from "../utils/ServicesUtils";

export interface PhanHeXuatKhoContextProps {
    thongTinPhieu: PhieuXuat;
    setThongTinPhieu: Dispatch<SetStateAction<PhieuXuat>>;
    vatTuSelected: any,
    setVatTuSelected: Dispatch<SetStateAction<any>>;
    onReset: () => void;
    randomValue: any,
    setRandomValue: Dispatch<SetStateAction<any>>;
}

const initialContext = {
    thongTinPhieu: initialPhieuXuat,
    setThongTinPhieu: () => {},
    vatTuSelected: {},
    setVatTuSelected: () => {},
    onReset: () => {},
    randomValue: null,
    setRandomValue: () => {},
}

export const PhanHeXuatKhoContext = createContext<PhanHeXuatKhoContextProps>(initialContext)

type Props = {
    warehouseId: number;
}

export const PhanHeXuatKho: FC<Props> = (props) => {
    const { warehouseId } = props;

    const [thongTinPhieu, setThongTinPhieu] = useState(initialPhieuXuat);
    const [vatTuSelected, setVatTuSelected] = useState(initialPhieuXuat);
    const [randomValue, setRandomValue] = useState();
    const [lstWarehouse, setLstWarehouse] = useState<any>({});

    const updateData = async () => {
        try {
          const data = await getListWarehouse();
          let newLstData: any = {};
          data.forEach((item: any) => {
            newLstData[item.id] = {
              mateWhId: item.id,
              mateWhName: item.name,
              mateWh: item.code,
            };
          });
          setLstWarehouse(newLstData);
        } catch (error) {
          console.error(error);
        }
      };
  
      useEffect(() => {
        updateData();
      }, []);

    return (
        <Formik<any>
            initialValues={{}}
            onSubmit={() => {}}
        >
            <PhanHeXuatKhoContext.Provider
                value={{
                    thongTinPhieu,
                    setThongTinPhieu,
                    vatTuSelected,
                    setVatTuSelected,
                    randomValue,
                    setRandomValue,
                    onReset: () => {
                        setThongTinPhieu(initialPhieuXuat);
                    }
                }}
            >
                <div className="bg-white">
                    <div className="flex-1 flex flex-column">
                        <div className="py-1">
                            <FormXuatKho
                                lstWarehouse={lstWarehouse}
                                warehouseId={warehouseId}
                            />
                        </div>
                    </div>
                </div>
            </PhanHeXuatKhoContext.Provider>
        </Formik>

    )
}

export default PhanHeXuatKho;
